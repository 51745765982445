import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as oomnielabsApi from '../../../apis/oomnielabs.js'
import MultiInput from '../../../components/MultiInput.js'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Svg from'../../../components/Svg.js'


const ChartFilter = (props) => {

  const mode = useSelector(state => state.environment.mode);
  
  const showFilter = props.showFilter || false
  const setShowFilter = props.setShowFilter || null
  const updateChart = props.updateChart || null

  const [filterData, setFilterData] = useState([{
    region:"All",
    business_unit:"All",
    asset_type:"All",
    product:"All",
    supplier:"All"
  }])

  const [formData, setFormData] = useState({
    start_date:"",
    end_date:"",
    time_interval:"",
    filters: {
      region:"All",
      business_unit:"All",
      asset_type:"All",
      product:"All",
      supplier:"All"
    }
  })


  const [ regionList, setRegionList] = useState(["All"])
  const [ businessUnitList, setBusinessUnitList] = useState(["All"])
  const [ assetTypeList, setAssetTypeList] = useState(["All"])
  const [ productList, setProductList] = useState(["All"])
  const [ supplierList, setSupplierList] = useState(["All"])

  const getRegionList = async()=>{
    const query = `SELECT json_agg(region) AS regions
                    FROM (
                        SELECT DISTINCT region
                        FROM assets
                        WHERE region IS NOT NULL
                        ORDER BY region ASC
                    ) subquery;`
    const dbName = "main"
    const response = await oomnielabsApi.getPythonData(query, dbName)
    setRegionList([...regionList,...response[0].regions])
  }

  const getBusinessUnitList = async()=>{
    const query = `SELECT json_agg(business_unit) AS business_units
                    FROM (
                        SELECT DISTINCT business_unit
                        FROM assets
                        WHERE business_unit IS NOT NULL
                        ORDER BY business_unit ASC
                    ) subquery;`

    const dbName = "main"
    const response = await oomnielabsApi.getPythonData(query,dbName)
    setBusinessUnitList([...businessUnitList,...response[0].business_units])
  }

  const getAssetTypeList = async()=>{
    const query = `SELECT json_agg(asset_category) AS asset_categories
                    FROM (
                        SELECT DISTINCT asset_category
                        FROM assets
                        WHERE asset_category IS NOT NULL
                        ORDER BY asset_category ASC
                    ) subquery;`
    const dbName = "main"
    const response = await oomnielabsApi.getPythonData(query,dbName)
    setAssetTypeList([...assetTypeList,...response[0].asset_categories])
  }

  const getProductList = async()=>{
    const query = `SELECT json_agg(product) AS products
                    FROM (
                        SELECT DISTINCT product
                        FROM assets
                        WHERE product IS NOT NULL
                        ORDER BY product ASC
                    ) subquery;`
    const dbName = "main"
    const response = await oomnielabsApi.getPythonData(query,dbName)
    setProductList([...productList,...response[0].products])
  }

  const getSupplierList = async()=>{
    const query = `SELECT json_agg(supplier) AS suppliers
                    FROM (
                        SELECT DISTINCT supplier
                        FROM assets
                        WHERE supplier IS NOT NULL
                        ORDER BY supplier ASC
                    ) subquery;`
    const dbName = "main"
    const response = await oomnielabsApi.getPythonData(query,dbName)
    setSupplierList([...supplierList,...response[0].suppliers])
  }

  useEffect(()=>{

      getRegionList()
      getBusinessUnitList()
      getAssetTypeList()
      getProductList()
      getSupplierList()
  },[])

  const handleInputChange = (e)=>{
    const {name, value} = e.target
    console.log({name, value})
    setFormData({...formData,[name]:value})
  }

  const handleFilterInputChange = (e)=>{
    const {name, value} = e.target
    
    const updatedFilterData = {...filterData,
      [name]:value
    }
    setFilterData(updatedFilterData)
    setFormData({...formData,filters:updatedFilterData})
  }

  const handleUpdateFilter = ()=>{
    console.log(formData)
    updateChart(formData)
    setShowFilter(false)
  }


 const handlResetFilter = ()=>{

    setFilterData({
      region:"All",
      business_unit:"All",
      asset_type:"All",
      product:"All",
      supplier:"All"
    })

    setFormData({...formData,
      start_date:"",
      end_date:"",
      time_interval:"",
    })
 }

  return (

    <div 
      className={`absolute panel-mode-${mode} w-[300px] h-auto p-3 right-[20px] top-[40px] bg-[rgb(200,200,200)] fade-in`}
      style={{zIndex: 9999}}
      >

      <div className={`flex flex-col w-full p-2 border-b-gray-300 border-b-[1px] mb-3`}>
        
        
        <div className={`flex w-full justify-end border-b-[1px] border-b-gray-300 mb-3 pb-3`}>

          <div title={"Reset"} onClick={(e)=>handlResetFilter()}>
              <div className={`button-mode-secondary-${mode}`}>Reset</div>
          </div>
          
            <div title={"Close"} onClick={(e)=>setShowFilter(false)}
              className={`ms-3`}
              >
              <Svg 
                iconName = {"CloseIcon"}
                height  = {30}
                width  = {30}
                fillColor  = {"rgb(150,150,150"}
                fillOpacity  = {1}
                isHovered  = {false}
                cursor  = {"pointer"}å
              />
            </div>

        </div>
        <div className="flex w-[100%]">
          <DatePicker
              selected={formData.start_date} // Bind selected date to state
              onChange={(date) => handleInputChange({ target: { name: 'start_date', value: new Date(date.setHours(0, 0, 0, 0)) } })} // Call handleChange with a Date
              name="start_date"             
              dateFormat="MM/d/yyyy"    
              placeholderText="Start Date"
              style={{
                width:"300px",
                height:"100px",
                color: "blue"
              }}
              className={`datepicker-mode-${mode}`}
            />
        </div>

        <div className="flex w-[100%]">
          <DatePicker
              selected={formData.end_date} // Bind selected date to state
              onChange={(date) => handleInputChange({ target: { name: 'end_date', value: new Date(date.setHours(0, 0, 0, 0)) } })} // Call handleChange with a Date
              name="end_date"             
              dateFormat="MM/d/yyyy"    
              placeholderText="End Date"
              className={`datepicker-mode-${mode}`}
            />
        </div>
          
          <div className="flex w-[100%]">
          <MultiInput
            id="time_interval"
            name="time_interval"
            label="Time Interval"
            value={formData.time_interval}
            list={["year", "month", "week", "day"]}
            onChange={(e)=>handleInputChange(e)}
          />
          </div>
      </div>


      <div className="flex flex-col w-full p-2 border-t-gray-300" >

        <MultiInput
          id="region"
          name="region"
          label="Region"
          value={filterData.region}
          list={regionList.length>0 ? regionList: ["All", "North America", "EMEA", "APJ", "LATAM", "Other"]}
          onChange = {(e)=>handleFilterInputChange(e)}
        />

        <MultiInput
          id="business_unit"
          name="business_unit"
          label="Business Unit"
          value={filterData.business_unit}
          list={businessUnitList.length>0 ? businessUnitList: ["All", "Sales", "Marketing", "Engineering", "Corporate", "Finance", "Customer Service"]}
          onChange = {(e)=>handleFilterInputChange(e)}
        />

        <MultiInput
          id="asset_type"
          name="asset_type"
          label="Asset Type"
          value={filterData.asset_type}
          list={assetTypeList.length>0 ? assetTypeList : ["All", "Hardware", "Software", "Network Devices", "Cloud Computing", "Peripherals", "Other"]}
          onChange = {(e)=>handleFilterInputChange(e)}
        />

        <MultiInput
          id="product"
          name="product"
          label="Product"
          value={filterData.product}
          list={productList.length>0 ? productList : ["All", "Laptop", "Servers", "Mobile Phone", "ERP", "CRM", "Other"]}
          onChange = {(e)=>handleFilterInputChange(e)}
        />

        <MultiInput
          id="supplier"
          name="supplier"
          label="Supplier"
          value={filterData.supplier}
          list={supplierList.length>0 ? supplierList : []}
          onChange = {(e)=>handleFilterInputChange(e)}
        />

      </div>
      
      <div className={`flex w-full justify-end p-3 mt-3`}>
        <div 
          className={`button-mode-${mode}`}
          onClick = {()=>handleUpdateFilter()}
          >Update</div>
      </div>


    </div>

  )
}

export default ChartFilter
