import React, {useState, useEffect} from "react"
import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../../redux/slices/oomnieInsightsGenieSlice';

const Navbar = ({}) => {

const dispatch = useDispatch();
const mode = useSelector(state => state.environment.mode)
const currentPage = useSelector(state => state.oomnieInsightsGenie.currentPage)

useEffect(()=>{
  console.log(currentPage)
},[currentPage])

  return (
      <div className={`flex h-[50px] w-full items-center text-[16px]]
          header-strip-mode-${mode}
          transition duration-500 mb-3` }>

          <div 
            className={
              `${currentPage === "summary" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
              w-fit p-2 m-2 cursor-pointer text-center`}
            onClick = {(e)=>dispatch(setCurrentPage("summary"))}
          >Summary</div>

          <div 
          className={
            `${currentPage === "financials" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
            w-fit p-2 m-2 cursor-pointer text-center`}
            onClick = {(e)=>dispatch(setCurrentPage("financials"))}
          >Financials</div>

          <div 
            className={
              `${currentPage === "lifecycle" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
              w-fit p-2 m-2 cursor-pointer text-center`}
            onClick =  {(e)=>dispatch(setCurrentPage("lifecycle"))}
          >Lifecycle</div>

          <div 
            className={
              `${currentPage === "relationships" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
            w-fit p-2 m-2 cursor-pointer text-center`}
            onClick =  {(e)=>dispatch(setCurrentPage("relationships"))}
          >Relationships</div>

          <div 
            className={
              `${currentPage === "locations" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
              w-fit p-2 m-2 cursor-pointer text-center`}
            onClick =  {(e)=>dispatch(setCurrentPage("locations"))}
          >Locations</div>

<div 
            className={
              `${currentPage === "ai_lab" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
              w-fit p-2 m-2 cursor-pointer text-center`}
            onClick =  {(e)=>dispatch(setCurrentPage("ai_lab"))}
          >AI Lab</div>

      </div>

  )
}

export default Navbar
