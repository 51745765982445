import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PowerManagement from './components/PowerManagement.js'
import MapView from './components/MapView.js';
import Navbar from './components/Navbar.js';

 
function App({contextApi}) {

  const dispatch = useDispatch();
  const mode = useSelector(state => state.environment.mode);
  const currentPage = useSelector(state => state.datacenterPowerOptimizer.currentPage);

  return (
    <div className={`flex flex-col w-full h-[100vh] overflow-hidden transition duration-500 bg-mode-${mode} `}>
        
        <Navbar contextApi={contextApi}/>
        {currentPage ==="mapview" && <MapView contextApi={contextApi}/>}
        {currentPage ==="power_management" && <PowerManagement contextApi={contextApi}/>}

    </div>
  );
}

export default App;

