import React, { useState } from "react";

const roomLength = 60;
const roomWidth = 60;
const cageLength = 15;
const cageWidth = 15;
const rackLength = 4;
const rackWidth = 2;

const dataCenterLayout = {
  width: 280,
  height: 200,
  rooms: [
    { id: "Room 1", x: 0, y: 0 },
    { id: "Room 2", x: 66, y: 0 },
    { id: "Room 3", x: 132, y: 0 },
  ],
  cages: [],
  racks: [],
  doors: [],
  inspectionStops: [
    { id: 1, x: 6, y: 10, room: "Room 1" },
    { id: 2, x: 18, y: 48, room: "Room 1" },
    { id: 3, x: 72, y: 20, room: "Room 2" },
    { id: 4, x: 108, y: 45, room: "Room 2" },
    { id: 5, x: 140, y: 15, room: "Room 3" },
    { id: 6, x: 180, y: 45, room: "Room 3" },
  ],
  route: [
    { x: 5, y: 5 }, // Start
    { x: 6, y: 10 },
    { x: 18, y: 48 },
    { x: 66, y: 0 }, // Through the door of Room 2
    { x: 72, y: 20 },
    { x: 108, y: 45 },
    { x: 132, y: 0 }, // Through the door of Room 3
    { x: 140, y: 15 },
    { x: 180, y: 45 },
  ],
};

// Generate cages and racks for each room
dataCenterLayout.rooms.forEach((room, roomIndex) => {
  const cages = [];
  for (let i = 0; i < 2; i++) {
    for (let j = 0; j < 5; j++) {
      const cageX = room.x + 2 + j * (cageLength + 2);
      const cageY = room.y + 2 + i * (cageWidth + 2);
      cages.push({ id: `Cage ${roomIndex + 1}-${i * 5 + j + 1}`, x: cageX, y: cageY });
      // Add racks to the cage
      for (let m = 0; m < 2; m++) {
        for (let n = 0; n < 3; n++) {
          const rackX = cageX + 4 + n * (rackLength + 3);
          const rackY = cageY + 3 + m * (rackWidth + 3);
          dataCenterLayout.racks.push({
            id: `Rack ${roomIndex + 1}-${i * 5 + j + 1}-${m * 3 + n + 1}`,
            x: rackX,
            y: rackY,
          });
        }
      }
    }
  }
  dataCenterLayout.cages.push(...cages);
  // Add door
  dataCenterLayout.doors.push({
    roomId: room.id,
    x: room.x + roomLength - 4,
    y: room.y,
  });
});

const RouteOptimizer = () => {
  const [selectedStop, setSelectedStop] = useState(null);

  const handleStopClick = (stop) => {
    setSelectedStop(stop);
  };

  return (
    <div className={`w-full h-[100%]`}>
      <h1>Data Center Visualization</h1>
      
      <svg
        width={dataCenterLayout.width}
        height={dataCenterLayout.height}
        className="data-center-layout"
        viewBox={`0 0 ${dataCenterLayout.width} ${dataCenterLayout.height}`}
      >
        {/* Draw rooms */}
        {dataCenterLayout.rooms.map((room) => (
          <rect
            key={room.id}
            x={room.x}
            y={room.y}
            width={roomLength}
            height={roomWidth}
            fill="#d9e8ff"
            stroke="#003366"
            strokeWidth="2"
          />
        ))}

        {/* Draw doors */}
        {dataCenterLayout.doors.map((door, idx) => (
          <rect
            key={`door-${idx}`}
            x={door.x}
            y={door.y}
            width={4}
            height={2}
            fill="#8bc34a"
          />
        ))}

        {/* Draw cages */}
        {dataCenterLayout.cages.map((cage) => (
          <rect
            key={cage.id}
            x={cage.x}
            y={cage.y}
            width={cageLength}
            height={cageWidth}
            fill="#fdd835"
            stroke="#ff9800"
            strokeWidth="1"
          />
        ))}

        {/* Draw racks */}
        {dataCenterLayout.racks.map((rack) => (
          <rect
            key={rack.id}
            x={rack.x}
            y={rack.y}
            width={rackLength}
            height={rackWidth}
            fill="#bdbdbd"
            stroke="#424242"
            strokeWidth="1"
          />
        ))}

        {/* Draw inspection stops */}
        {dataCenterLayout.inspectionStops.map((stop) => (
          <circle
            key={stop.id}
            cx={stop.x}
            cy={stop.y}
            r="2"
            fill="#4caf50"
            stroke="#2e7d32"
            strokeWidth="1"
            onClick={() => handleStopClick(stop)}
          />
        ))}

        {/* Draw route */}
        <polyline
          points={dataCenterLayout.route.map(({ x, y }) => `${x},${y}`).join(" ")}
          fill="none"
          stroke="#4caf50"
          strokeWidth="1"
          strokeDasharray="4,2"
        />
      </svg>

      {selectedStop && (
        <div className="details">
          <h2>Inspection Stop Details</h2>
          <p><b>ID:</b> {selectedStop.id}</p>
          <p><b>Room:</b> {selectedStop.room}</p>
          <p><b>Coordinates:</b> ({selectedStop.x}, {selectedStop.y})</p>
        </div>
      )}

      <div className="summary">
        <h2>Summary</h2>
        <p><b>Total Distance:</b> 200 units</p>
        <p><b>Total Time:</b> 60 minutes</p>
      </div>
    </div>
  )

}

export default RouteOptimizer
