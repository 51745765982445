import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {setMode} from '../../../redux/slices/envSlice'

const AIStudio = () => {

  const mode = useSelector(state=>state.environment.mode)
  const dispatch = useDispatch()

  useEffect(()=>{
    dispatch(setMode("dark"))
  },[])

  const apps =[
    {id: 1, name: "forecast", label: "Forecast", icon: "", link: ""},
    {id: 2, name: "prediction", label: "Prediction", icon: "", link: ""},
    {id: 3, name: "classification", label: "Classification", icon: "", link: ""},
    {id: 4, name: "principal_components", label: "Principal Components", icon: "", link: ""},
    {id: 5, name: "clustering", label: "Clustering", icon: "", link: ""},
    {id: 6, name: "prescriptions", label: "Prescriptions", icon: "", link: ""},
    {id: 7, name: "relationship_graphs", label: "Relationship Graphs", icon: "", link: ""},
  ]

  const handleRedirect = ()=>{

  }

  return (
    <div className={`flex flex-col items-center w-full h-[100vh] mt-5 fade-in`}>
         <img
            className="flex mb-5 transition duration-500"
            style={{ width: "200px" }}
            src="https://oomnielabs.s3.us-west-2.amazonaws.com/graphics/images/Octopus+in+lab+coat.png"
            alt="Octopus"
          />
          <h1 className={`text-[32px]`}>Coming Soon...</h1>
          <div className="flex w-full md:w-[800px] justify-center flex-wrap overflow-y-auto mt-5">
            {apps && 
                apps.map((app,index)=>(
                <div 
                    key={index} 
                    className={`flex flex-col panel-mode-${mode} items-center justify-center h-[100px] md:h-[100px] w-full md:w-[200px] rounded-md shadow-lg p-3 m-3 cursor-pointer transition duration-500 hover:scale-110`}
                    onClick={(e)=>handleRedirect(app.link)}
                    >
                    <div className={`text-[20px] text-center primary-color-mode-${mode} mb-3`}>{app.label}</div>
                    {/* <div className={`text-[14px] secondary-color-mode-${mode}` }>{app.description}</div> */}
                </div>
                ))
            }
        </div>
    </div>
  )
}

export default AIStudio
