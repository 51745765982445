import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ColumnChart from './ColumnChart.js'
import * as oomnielabsApi from '../../../apis/oomnielabs.js'
import Svg from'../../../components/Svg.js'
import ChartFilter from './ChartFilter.js';
import MultiSeriesBarChart from './MultiSeriesBarChart.js';
import DoughnutChart from './DoughnutChart.js';
import RemainingLifeCycle from './RemainingLifeCycle.js'
import ProbabilityOfFailure from './ProbabilityOfFailure.js'
import DaysToMaintenance from './DaysToMaintenance.js'
import Table from '../../../components/Table.js';
import Kanban from './Kanban.js';
import '../kanban.css'



const LifeCycle = (props) => {

  const mode = useSelector(state => state.environment.mode);
  const [showFilter, setShowFilter] = useState(false)
  const [formData, setFormData] = useState({})
  const [data, setData] = useState([])
  const [categories, setCategories] = useState([])
  const [remainingLifeCycle, setRemainingLifeCycle] = useState([])
  const [probabilityOfFailure, setProbabilityOfFailure] = useState([])
  const [daysToMaintenance, setDaysToMaintenance] = useState([])
  const [avgRemainingLifeCycle, setAvgRemainingLifeCycle] = useState(87)
  const [avgProbabilityOfFailure, setAvgProbabilityOfFailure] = useState(25)
  const [avgDaysToMaintenance, setAvgDaysToMaintenance] = useState(92)

  const getData = async()=>{
    
    const query = `
    SELECT 
        asset_category as "Category",
        COUNT(DISTINCT id) AS "Number of Assets",
        ROUND(AVG(remaining_lifecycle),2) AS "Avg Remaining Life %",
        ROUND(AVG(probability_of_failure),2) AS "Avg Probability of Failure",
        ROUND(AVG(days_to_next_maintenance),2) AS "Avg Days To Next Maintenance",
        ROUND(SUM(original_purchase_price),2) AS "Total Asset Value",
        COUNT(DISTINCT assigned_to_id) AS "Number of Users Impacted"
    FROM assets
    GROUP BY "Category"
    ORDER BY "Category";
    `
    const dbName = "main"
    const response = await oomnielabsApi.getPythonData(query,dbName)
    let data = response
    console.log(data)
    setData(data)

    let categoriesSet = new Set()
    data.map(item=>{
      categoriesSet.add(item["Category"])
    })
    const categoryList = Array.from(categoriesSet)
    setCategories(categoryList)


    let remainingLifeCycleSet = new Set()
    data.map(item=>{
      remainingLifeCycleSet.add(item["Avg Remaining Life %"])
    })
    const remainingLifeCycleList = Array.from(remainingLifeCycleSet)
    setRemainingLifeCycle(remainingLifeCycleList)
    console.log(data.reduce((acc, obj) => acc + obj["Avg Remaining Life %"], 0) / data.length)
    const avgRemainingLife = data.map(item => parseFloat(item["Avg Remaining Life %"])) // Convert to number
    .reduce((acc, value) => acc + value, 0) // Sum up the values
    / data.length;
    setAvgRemainingLifeCycle(avgRemainingLife.toFixed(2))


    let probabilityOfFailureSet = new Set()
    data.map(item=>{
      probabilityOfFailureSet.add(item["Avg Probability of Failure"])
    })
    const probabilityOfFailureList = Array.from(probabilityOfFailureSet)
    setProbabilityOfFailure(probabilityOfFailureList)
    const avgProbabilityOfFailure= data.map(item => parseFloat(item["Avg Probability of Failure"])) // Convert to number
    .reduce((acc, value) => acc + value, 0) // Sum up the values
    / data.length;
    setAvgProbabilityOfFailure(avgProbabilityOfFailure.toFixed(2))



    let days_to_next_maintenanceSet = new Set()
    data.map(item=>{
      days_to_next_maintenanceSet.add(item["Avg Days To Next Maintenance"])
    })
    const days_to_next_maintenanceList = Array.from(days_to_next_maintenanceSet)
    setDaysToMaintenance(days_to_next_maintenanceList)
    const avgDaysToMaintenance= data.map(item => parseFloat(item["Avg Days To Next Maintenance"])) // Convert to number
    .reduce((acc, value) => acc + value, 0) // Sum up the values
    / data.length;
    setAvgDaysToMaintenance(avgDaysToMaintenance.toFixed(1))

  }


  const [commodityLevelData, setCommodityLevelData] = useState([])
  const getCommodityLevel = async()=>{
    
    const query = `
    SELECT 
        commodity_type as "Commodity Type",
        COUNT(DISTINCT id) AS "Number of Assets",
        ROUND(AVG(remaining_lifecycle),2) AS "Avg Remaining Life %",
        ROUND(AVG(probability_of_failure),2) AS "Avg Probability of Failure",
        ROUND(AVG(days_to_next_maintenance),2) AS "Avg Days To Next Maintenance",
        ROUND(SUM(original_purchase_price),2) AS "Total Asset Value",
        COUNT(DISTINCT assigned_to_id) AS "Number of Users Impacted"
    FROM assets
    GROUP BY "Commodity Type"
    ORDER BY "Commodity Type";
    `
    const dbName = "main"
    const response = await oomnielabsApi.getPythonData(query,dbName)
    let data = response
    console.log(data)
    setCommodityLevelData(data)

  }

  const [stageSummary, setStageSummary] = useState([])
  const getStageSummary = async()=>{
    
    const query = `
    SELECT 
        stage as "Stage",
        COUNT(DISTINCT id) AS "Number of Assets",
        ROUND(SUM(original_purchase_price),2) AS "Total Asset Value",
        COUNT(DISTINCT assigned_to_id) AS "Number of Users Impacted"
    FROM assets
    GROUP BY "Stage"
    ORDER BY "Stage";
    `
    const dbName = "main"
    const response = await oomnielabsApi.getPythonData(query,dbName)
    let data = response
    console.log(data)
    setStageSummary(data)
  }


  const [assets, setAssets] = useState([])
  const getAssets = async()=>{
    const dbName = "main"
    const response = await oomnielabsApi.getPythonTable("assets",dbName)
    let data = response
    setAssets(data)
  }

  useEffect(()=>{
    getData()
    getCommodityLevel()
    getStageSummary()
    getAssets()
  },[])

  const handleRecordSelect = (records)=>[
    console.log(records)
  ]


  const [showTables, setShowTables] = useState(false)

  return (

    <div className="flex flex-col h-[100vh] w-full fade-in items-center overflow-hidden p-5">

    {/* Charts */}
    <div className={`flex flex-col w-full h-auto transition duration-500 overflow-hidden`}>

    <div className={`flex w-full h-auto`}>

    <div className={`flex flex-col w-1/2 h-[350px] items-center border-[1px] rounded-md shadow-md m-2 p-2`}>
      <div className={`flex w-full text-[18px] text-center justify-center font-bold`}>Assets by Stage</div>
        <MultiSeriesBarChart 
          label = {"Assets By Stage"}
          labels = {["Ordered", "Receiving", "In Inventory", "Provisioning","Deployed", "Maintenance","Decommissioned"]}           
          datasets = {[
            {
              label: "Number of Assets",
              values: [266, 13, 111, 215, 200, 108, 79],
              backgroundColor: 'rgba(0, 150, 225, 0.6)',
            }   
          ]}
          yAxisTitle = "Percent"
          xAxisTitle = ""
          mode = {mode}
        />
        
    </div>

    <div className={`flex flex-col w-1/2 h-[350px] items-center border-[1px] rounded-md shadow-md m-2 p-2`}>
      <div className={`flex w-full text-[18px] text-center justify-center font-bold`}>Remaining Life</div>
        <MultiSeriesBarChart 
          label = {"Remaining Lifecycle"}
          labels = {["Cloud Computing", "Hardware", "Network & Data Center", "Software"]}           
          datasets = {[
            {
              label: "Percent",
              values: [20, 40, 30, 60],
              backgroundColor: 'rgba(0, 150, 225, 0.6)',
            }   
          ]}
          yAxisTitle = "Percent"
          xAxisTitle = ""
          mode = {mode}
        />
        
    </div>

    </div>
    
  
    {!showTables && assets.length>0 &&
      <div className={`flex w-full h-[600px] bg-gray-100`}>
        <Kanban 
          assets = {assets} 
          />
      </div>
    }

    {/* table */}
    {
      showTables && data.length>0 &&
      <div className={`flex flex-col w-full`}>
        <div className={`text-[16px] font-bold mb-3`}>Category Level</div>
        <div className={`flex flex-col w-full h-[300px] mb-3 border-[1px] rounded-md shadow-md`}>
          <Table 
              data = {data}
              includeRowSelect = {true}
              formatHeader = {true}
              onRowSelected = {(record)=>handleRecordSelect(record)}
              // hiddenColumns = {hiddenColumns}
              mode = {mode}
          />
        </div>

        
      {
      showTables && commodityLevelData.length>0 &&  
      <div className={`flex flex-col w-full`}>
        <div className={`text-[16px] font-bold mb-3`}>Commodity Level</div>
        <div className={`flex flex-col w-full h-[500px] mb-3 border-[1px] rounded-md shadow-md`}>
          <Table 
              data = {commodityLevelData}
              includeRowSelect = {true}
              formatHeader = {true}
              onRowSelected = {(record)=>handleRecordSelect(record)}
              // hiddenColumns = {hiddenColumns}
              mode = {mode}
          />
        </div>
      </div>
      }
      </div>
    }


{
      
    }

    </div>

     

    </div>
  )
}

export default LifeCycle
