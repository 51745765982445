import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import "react-datepicker/dist/react-datepicker.css";
import SpendAnalysis from './SpendAnalysis';
import OpexCapexAnalysis from './OpexCapexAnalysis'
import WorkingCapitalImpact from './WorkingCapitalmpact';

const Financials = ({contextApi}) => {

  const mode = useSelector(state => state.environment.mode);

  return (
    <div className="flex flex-col h-[100%] w-full fade-in items-center overflow-y-auto p-5">
      
      {/* Sub Menu */}
      <div className={`flex w-full h-[50px]`}>

      </div>


      {/* Charts */}
     <div className={`flex flex-col md:w-[1200px] min-w-[600px] h-[5000px] justify-center transition duration-500`}>
        <SpendAnalysis />
        <OpexCapexAnalysis />
        <WorkingCapitalImpact />
    </div>

    </div>
  )
}

export default Financials

