import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';

import * as oomnielabsApi from '../../../apis/oomnielabs.js'
import "react-datepicker/dist/react-datepicker.css";

import ChartFilter from './ChartFilter.js';

import DoughnutChart from "./DoughnutChart.js"
import MultiSeriesBarChart from './MultiSeriesBarChart.js';
import Svg from '../../../components/Svg.js';


const CycleCount = (props) => {

  const mode = useSelector(state => state.environment.mode);
  const [showFilter, setShowFilter] = useState(false)
  const [formData, setFormData] = useState({})
  const [data, setData] = useState([])

  const updateChart = async (formData)=>{
    console.log(formData)
    const app_name = "asset_analysis"
    const main_function = "run_analysis"
    const parameters = formData
    try{
      const response = await oomnielabsApi.pythonApp(app_name,main_function, parameters)
      console.log(response)
      setData(response.data)
    }catch(error){
      console.log(error)
    }
}


  return (

    <div className={`relative flex w-full h-[100%]`}>

       {/* Cycle Count */}
      <div className={`flex flex-col w-full h-[auto] panel-mode-${mode} mb-5 rounded-md`}>
        
        {/* Chart Header */}
        <div className={`flex w-full h-[50px] items-center header-strip-mode-${mode} pe-3 justify-end`}>
          <div title={"Filter"} onClick={(e)=>setShowFilter(!showFilter)}>
            <Svg 
              iconName = {"FilterIcon"}
              height  = {30}
              width  = {30}
              fillColor  = {"rgb(150,150,150"}
              fillOpacity  = {1}
              isHovered  = {false}
              cursor  = {"pointer"}å
            />
          </div>
        </div>


        {/* Chart Body */}  
        <div className={`flex w-full h-[400px] overflow-hidden rounded-md p-3`}>
          
          {/* Doughnut Chart */}
          <div className="relative flex flex-col w-1/5 h-[350px] justify-center items-center">
            <div className="flex flex-col h-[250px] justify-center items-center">
              <DoughnutChart
                labels = {['Blind', 'Dynamic']}
                values = {[30, 70]}
                measureLabel = {"Percent of Total"}
                series1Color = {"rgba(0,150,225,0.6)"}
                series2Color = {"rgba(0,225,150,0.6)"}
                mode = {mode}
              />
            </div>

            <div className="absolute top-[50%] flex w-full flex-col justify-center">
              <div className={`flex w-full justify-center font-bold text-[28px] primary-color-mode-${mode}`}>20%</div>
              <div className={`flex w-full justify-center text-[12px] secondary-color-mode-${mode}`}>Overall Cycle Count</div>
            </div>
          </div>

          {/* Bar Chart */}
          <div className={`flex flex-col h-[350px] w-2/5 p-3`}>
            <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">Cycle Count By Asset Type</div>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">CapEx has increased from 1.8x to 3x of Opex.  This exceeds your peer group average.</div>
            <div className="flex w-full h-[300px]">
              <MultiSeriesBarChart
                 label = "Cycle Count By Asset Type"
                 labels = {['Servers', 'Network', 'Storage', 'Power & Cooling', 'Parts']}
                datasets = {[
                  {
                    label: 'Dynamic',
                    values: [70, 67, 65, 68, 72, 75, 73, 74, 72, 71, 69, 70],
                    backgroundColor: 'rgba(50, 200, 100, 0.6)',
                  },
                  {
                    label: 'Blind',
                    values: [30, 33, 35, 32, 28, 25, 29, 26, 28, 29, 31, 30],
                    backgroundColor: 'rgba(200, 200, 200, 0.6)',
                  },
                ]}
                barColor = "rgba(0,200,180,0.5)"
                xAxisTitle = ""
                yAxisTitle = "Percent"
                mode = {mode}
                stackedX={true}
                stackedY={true}
              />
            </div>
          </div>


          <div className={`flex flex-col h-[350px] w-2/5 p-3`}>
            <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">Cycle Count By Asset Type</div>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">CapEx has increased from 1.8x to 3x of Opex.  This exceeds your peer group average.</div>
            <div className="flex w-full h-[300px]">
              <MultiSeriesBarChart
                label = "Cycle Count By Month"
                labels = {['Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'July-24', 'Aug-24', 'Sep-24']}
                datasets = {[
                  {
                    label: 'Dynamic',
                    values: [70, 67, 65, 68, 72, 75, 73, 74, 72, 71, 69, 70],
                    backgroundColor: 'rgba(50, 200, 100, 0.6)',
                  },
                  {
                    label: 'Blind',
                    values: [30, 33, 35, 32, 28, 25, 29, 26, 28, 29, 31, 30],
                    backgroundColor: 'rgba(200, 200, 200, 0.6)',
                  },
                ]}
                barColor = "rgba(0,200,180,0.5)"
                yAxisTitle = "Percent"
                xAxisTitle = ""
                stackedX={true}
                stackedY={true}
                mode = {mode}
              />
            </div>
          </div>

        </div>

      </div>
 
      {/* Filter Menu */}
      {showFilter && 
        <ChartFilter 
          showFilter = {showFilter}
          setShowFilter = {setShowFilter}
          updateFormData = {setFormData}
          updateChart = {(formData)=>updateChart(formData)}
        />
      }

    </div>
  )
}

export default CycleCount
