import { createSlice } from '@reduxjs/toolkit'
import { PURGE } from 'redux-persist';

export const dataCenterSlice = createSlice({
  name: 'datacenter',
  initialState: {
    pages: [],
    currentPage: "details",
    selectedObjects: [],
    viewSettings: {
        maxX: 0,
        maxY: 0,
        maxZ: 0,
        maxDimensions: {
          x: 500,
          y: 500,
          z: 500
        },
        viewType: "3D",
        highllightStatus: false,
        opacity: 0.6,
        showEdges: true,
        enableZoom: true,
        enableRotate: true,
        rotateSpeed: 0.5,
        enablePan: true,
        panSpeed: 0.5,
        fov: 25,
        cameraX: 500,
        cameraY: 500,
        cameraZ: 500,
        targetX: 0,
        targetY: 0,
        targetZ: 0,
        near: 1000,
        far: 1000,
        up: [0,0,-1],
        showLabel: false
      },
      showObjects: {
            room: true,
            cage: true,
            cabinet: true,
            server: true,
            other: false
        }
  },
  reducers: {
    setPages: (state, action) => {
      state.pages = action.payload
    },
    setCurrentPage: (state, action) => {
      state.currentPage = action.payload
    },
    setSelectedObjects: (state, action) => {
      state.selectedObjects = action.payload
    }, 
    setViewSettings: (state, action) => {
      state.viewSettings = action.payload
    }, 
    setShowObjects: (state, action) => {
      state.showObjects = action.payload
    }, 
  },
  extraReducers: (builder) => {
    builder.addCase(PURGE, (state) => {
      // Reset to initialState when PURGE is triggered
      state.pages = [];
      state.currentPage = "details";
      state.selectedObjects = null;
      state.viewSettings = null;
      state.showObjects = {
          room: true,
          cage: true,
          cabinet: true,
          server: true,
          other: false
        }
    })}
  });

// Action creators are generated for each case reducer function
export const { setCurrentPage, setcurrentApp, setPageList, setSelectedObjects, setViewSettings, setShowObjects} = dataCenterSlice.actions

export const clearStorage = () => ({ type: PURGE, key: 'dataCenterStorage', result: () => null });

export default dataCenterSlice.reducer