import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { Canvas, useThree } from "@react-three/fiber";
import * as THREE from 'three'; // Import THREE for geometry
import { OrbitControls, OrthographicCamera, Text } from "@react-three/drei"; // Import OrthographicCamera
import Slider from "./Slider";
import { toProperCase } from "../functions/formatValue";

const SpaceObject = ({width,
  height,
  depth,
  x,
  y,
  z,
  color,
  label,
  showLabel,
  onClick,
  opacity,
  highlightStatus,
  showEdges,
  mode,
}) => {
  // Create a geometry with top-left origin translation
  const geometry = new THREE.BoxGeometry(width, height, depth);
  geometry.translate(width / 2, height / 2, depth / 2);

  return (
    <>
      {/* Main Box */}
      <mesh position={[x, y, z]} onClick={onClick}>
        <primitive attach="geometry" object={geometry} />
        <meshStandardMaterial color={color} transparent={true} opacity={opacity} />
      </mesh>

      {/* Optional Edges */}
      {showEdges && (
        <lineSegments position={[x, y, z]}>
          <edgesGeometry args={[geometry]} />
          <lineBasicMaterial color="lightgray" linewidth={1} />
        </lineSegments>
      )}

      {/* Optional Label */}
      {showLabel && (
        <Text
          position={[x, y, z]}
          fontSize={4}
          color="white"
          anchorX="center"
          anchorY="middle"
        >
          {label}
        </Text>
      )}
    </>
  );
};



const Scene = ({objects, selectedObjects, setSelectedObjects, showObjects, opacity, highlightStatus, maxDimensions, showLabel, showEdges }) => {
  
  return (
    <>
      {objects.map((item, index) => (
        showObjects[item.object_type]&&
          <SpaceObject
          id={item.id}
          key={index}
          width={parseInt(item.width)}
          height={parseInt(item.height)}
          depth={parseInt(item.depth)}
          x={parseInt(item.x)}
          y={parseInt(item.y)}
          z={parseInt(item.z)}
          color={
            selectedObjects && selectedObjects.id !==item.id ? "rgb(235,235,235)"
            :
            highlightStatus && selectedObjects.id !==item.id ? item.status_color
            :
            item.default_color
          }
          label={item.label}
          showLabel={showLabel}
          onClick={() => setSelectedObjects(item)}
          opacity = {opacity}
          showEdges = {showEdges}
        />
      ))}
    </>
  );
};

const CameraController = ({ cameraX, cameraY, cameraZ, fov}) => {
  
  const { camera, gl } = useThree();

  useEffect(() => {
      // Perspective camera properties for regular 3D view
      camera.position.set(cameraX, cameraY, cameraZ);
      camera.fov = fov;
      camera.updateProjectionMatrix();
  }, [cameraX, cameraY, cameraZ, fov, camera]);

  return null; // No need to render anything
};

const View = (props) => {

  const mode = useSelector(state=>state.environment.mode)

  const selectedObjects = props.selectedObjects || null
  const statuses = props.statuses || []
  const objects = props.objects || []
  const showObjects = props.showObjects || null
  const setShowObjects = props.setShowObjects || null
  const viewSettings = props.viewSettings || null
  
  const [maxX, setMaxX] = useState(0)
  const [maxY, setMaxY] = useState(0)
  const [maxZ, setMaxZ] = useState(0)
  const [maxDimensions, setMaxDimensions] = useState({x: 500, y:500, z:500})

  const [viewType, setViewType] = useState("3D")
  const [highlightStatus, setHighlightStatus] = useState(false)
  const [opacity, setOpacity] = useState(0.6)
  const [showEdges, setShowEdges] = useState(true)

  const [enableZoom, setEnableZoom] = useState(0.5)
  const [zoomSpeed, setZoomSpeed] = useState(true)
  const [enableRotate, setEnableRotate] = useState(true)
  const [rotateSpeed, enableRotateSpeed] = useState(0.5)
  const [enablePan, setEnablePan] = useState(true)
  const [panSpeed, setPanSpeed] = useState(0.5)


  // for 3D View
  const [fov, setFov] = useState(25); // Default field of view (FOV)
  const [cameraX, setCameraX] = useState(500);
  const [cameraY, setCameraY] = useState(500);
  const [cameraZ, setCameraZ] = useState(350);

  const [targetX, setTargetX] = useState(0);
  const [targetY, setTargetY] = useState(0);
  const [targetZ, setTargetZ] = useState(0);

  // For 2D views:
  const [zoom, setZoom] = useState(1)
  const [position, setPosition] = useState([maxX/2, maxY/2, maxZ/2])
  const [near, setNear] = useState(1000)
  const [far, setFar] = useState(1000)
  const [up, setUp] = useState([0, 0, -1])

  const [showLabel, setShowLabel] = useState(false)
  const [controlType, setControlType] = useState(false)

  const updateView = (selectedView)=>{
    if(selectedView==="top"){
      setCameraX(150)
      setCameraY(1000)
      setCameraZ(150)
      setTargetX(150)
      setTargetY(0)
      setTargetZ(150)
    }
  }

  const clearSelection = ()=>{
    props.setSelectedObjects(null)
  }

  useEffect(()=>{

  },[])

  return (
    <div className="flex flex-col w-full h-[100%] overflow-hidden">
    
      <div className={`flex flex-col items-center w-full h-[50px] p-3`}>

        <div className={`flex w-full h-[35px]`}>

          <div className="me-5">
            <Slider 
              label="Zoom"
              units = ""
              min = {0}
              max = {100}
              value={fov}
              updateParent={setFov}
              width = {"100px"}
              disabled={viewType==="3D"? false: true}
              fontColor = {`primary-color-mode-${mode}`}
            />
          </div>

          <div className="me-5">
            <Slider 
              label="Opacity"
              units = ""
              min = {0}
              max = {100}
              value={opacity*100}
              updateParent={(val)=>setOpacity(val/100)}
              width = "100px"
              fontColor = {`primary-color-mode-${mode}`}
            />
          </div>

          <div className={`flex p-1 primary-color-mode-${mode} me-5 items-center`}>
            <input
              type="checkbox"
              className="me-1"
              checked={highlightStatus}
              onChange={(e) => setHighlightStatus(!highlightStatus)}
            />
            <label>Show Status</label>
          </div>

          <div className={`flex p-1 primary-color-mode-${mode} me-5 items-center`}>
            <input
              type="checkbox"
              className="me-1"
              checked={showEdges}
              onChange={(e) => setShowEdges(!showEdges)}
            />
            <label>Show Edges</label>
          </div>

          {Object.entries(showObjects).map(([k,v],index)=>(
            <div className={`flex p-1 primary-color-mode-${mode} me-5 items-center`}>
            <input
              type="checkbox"
              className="me-1"
              checked={v}
              onChange={(e) => setShowObjects({...showObjects,...{[k]:!v}})}
            />
            <label>{k}</label>
          </div>
          ))}

          <div
              className={`button-mode-${mode} ms-1`}
              onClick = {(e)=>clearSelection(null)}
              >Clear
          </div>

        </div>

        <div className={`flex w-full h-[35px]`}>

          <button
            className={`button-mode-secondary-${mode} ms-1 cursor-pointer`}
            onClick = {(e)=>updateView("top")}
            >Top</button>

          <button
            className={`button-mode-secondary-${mode} ms-1`}
            onClick = {(e)=>updateView("front")}
            >Front</button>

          <button
          className={`button-mode-secondary-${mode} ms-1`}
            onClick = {(e)=>updateView("left")}
            >Left</button>

          <button
            className={`button-mode-secondary-${mode} ms-1`}
            onClick = {(e)=>updateView("right")}
            >Right</button>

          <button
            className={`button-mode-secondary-${mode} ms-1`}
            onClick = {(e)=>updateView("back")}
            >Back</button>
        </div>

      </div>
      
  
      <div className="relative flex flex-col w-full overflow-hidden h-[90vh]">

        <Canvas>
          {/* Ambient light for general lighting */}
          <ambientLight intensity={1} />
          {/* Directional light to cast shadows */}
          <directionalLight position={[-75, 200, 300]} intensity={1} />

          {/* OrbitControls for interacting with the view */}
          <OrbitControls
            target={[targetX, targetY, targetZ]}
            enableZoom={enableZoom}
            zoomSpeed={zoomSpeed}
            enableRotate={viewType === "3D"}  // Enable rotation only for 3D view
            rotateSpeed={rotateSpeed}
            enablePan={enablePan}
            panSpeed={panSpeed}
          />

          {/* Camera logic */}
          {viewType !== "3D" ? (
            <OrthographicCamera
              makeDefault
              zoom={zoom} // Adjust the zoom level for plan/elevation views
              position={position} // Position based on selected view
              near={near}
              far={far}
              up={up} // Set up vector for correct orientation
            />
          ) : (
            <CameraController
              cameraX={cameraX} 
              cameraY={cameraY} 
              cameraZ={cameraZ} 
              fov={fov}
            />
          )}

          {/* Scene with objects */}
          {objects.length>0 && showObjects && 
          <Scene 
            objects = {objects}
            selectedObjects = {selectedObjects}
            setSelectedObjects={props.setSelectedObjects}
            showObjects = {showObjects}
            setShowObjects = {setShowObjects}
            opacity={opacity}
            highlightStatus={highlightStatus}
            maxDimensions={maxDimensions}
            showLabel={showLabel}
            showEdges={showEdges}
          />}
        </Canvas>
    
        {
        highlightStatus && statuses.length>0 &&
          <div className={`absolute right-0 fade-in overflow-y-scroll shadow-md p-2 text-[12px] rounded-md m-2
            bg-mode-${mode} border-mode-${mode} primary-color-mode-${mode}
          transition duration-500`}>
            <div className="flex w-full h-[25px] text-[12px] items-center">Status</div>
            <table className="text-[12px]">
              <tbody>
              {statuses.map((item,index)=>(
                <tr key={index}>
                  <td className={`text-left h-[25px] p-1 w-1/4`} style={{backgroundColor: item.color}}></td>
                  <td className={`text-left h-[25px] ${mode ? "mode-text" : "mode-text"}  p-1`}>{item.status}</td>
                </tr>
              ))}
              </tbody>
          </table>
          </div>   
        } 
      
      </div>

    </div>
  );
};

export default View;
