import React, { useState } from "react";
import { useSelector, useDispatch } from 'react-redux';
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import { setCurrentPage } from "../../../redux/slices/navSlice";

// Custom marker icon
const markerIcon = new L.Icon({

  iconUrl: "https://upload.wikimedia.org/wikipedia/commons/e/ec/RedDot.svg", // Replace with your preferred marker image
  iconSize: [25, 25],
});

// const oomnieiqs = [
//   { id: 123423, name: "Macbook Pro M3", lat: 37.7258, lng: -122.1569, ""Owner"": "Avik Ghosh", "Annual Cost": "$35.2M", "Carbon Footprint": "0.8MT CO2" }, 
//   { id: 246576, name: "Apple Iphone 16", lat: 39.9612, lng: -82.9988, ""Owner"": "John Williams", "Annual Cost": "$29.3M", "Carbon Footprint": "0.8MT CO2" },
//   { id: 345852, name: "Lenovo", lat: 39.9612, lng: -74.4057, ""Owner"": "Mary Stevens", "Annual Cost": "$25.6M", "Carbon Footprint": "0.8MT CO2" }, 
//   { id: 456893, name: "Ipad Air", lat: 37.4316, lng: -78.6569, ""Owner"": "George Maxwell", "Annual Cost": "$22.7M", "Carbon Footprint": "0.8MT CO2" },
//   { id: 554575, name: "Dell Laptop", lat: 33.9943, lng: -117.4972, ""Owner"": "Donna Wilczek", "Annual Cost": "$27.2M", "Carbon Footprint": "0.8MT CO2" },
//   { id: 123758, name: "Macbook M1", lat: 38.7258, lng: -118.1569, ""Owner"": 500, "Annual Cost": "$35.2M", "Carbon Footprint": "0.8MT CO2" }, 
//   { id: 335435, name: "", lat: 38.9612, lng: -81.9988, ""Owner"": 500, "Annual Cost": "$29.3M", "Carbon Footprint": "0.8MT CO2" },
//   { id: 386565, name: "New Jersey", lat: 41.9612, lng: -74.4057, ""Owner"": 500, "Annual Cost": "$25.6M", "Carbon Footprint": "0.8MT CO2" }, 
//   { id: 789354, name: "Virginia", lat: 45.4316, lng: -80.6569, ""Owner"": 500, "Annual Cost": "$22.7M", "Carbon Footprint": "0.8MT CO2" },
//   { id: 234233, name: "Jurupa Valley", lat: 45.9943, lng: -120.4972, ""Owner"": 500, "Annual Cost": "$27.2M", "Carbon Footprint": "0.8MT CO2" },
//   { id: 463445, name: "Jurupa Valley", lat: 38.9943, lng: -97.4972, ""Owner"": 500, "Annual Cost": "$27.2M", "Carbon Footprint": "0.8MT CO2" },
//   { id: 234673, name: "Jurupa Valley", lat: 45.9943, lng: -96.4972, ""Owner"": 500, "Annual Cost": "$27.2M", "Carbon Footprint": "0.8MT CO2" },  
// ];

const oomnieiqs = [
  { id: 1, name: "Apple iPad Pro", city: "New York", state: "NY", longitude: -74.0060, latitude: 40.7128, Owner: "John Doe", "Annual Cost": 5000, "CO2 Emissions": 1200 },
  { id: 2, name: "Lenovo ThinkPad X1 Carbon", city: "San Francisco", state: "CA", longitude: -122.4194, latitude: 37.7749, Owner: "Jane Smith", "Annual Cost": 3000, "CO2 Emissions": 800 },
  { id: 3, name: "Dell PowerEdge Servers", city: "Austin", state: "TX", longitude: -97.7431, latitude: 30.2672, Owner: "Michael Johnson", "Annual Cost": 1500, "CO2 Emissions": 350 },
  { id: 4, name: "Apple Magic Keyboard", city: "Los Angeles", state: "CA", longitude: -118.2437, latitude: 34.0522, Owner: "Emily Clark", "Annual Cost": 2000, "CO2 Emissions": 400 },
  { id: 5, name: "HP Elite Dragonfly", city: "Chicago", state: "IL", longitude: -87.6298, latitude: 41.8781, Owner: "David Lee", "Annual Cost": 7000, "CO2 Emissions": 1600 },
  { id: 6, name: "Acer Predator X38", city: "Miami", state: "FL", longitude: -80.1918, latitude: 25.7617, Owner: "Sarah Miller", "Annual Cost": 800, "CO2 Emissions": 200 },
  { id: 7, name: "Cisco ASA Firewalls", city: "Seattle", state: "WA", longitude: -122.3321, latitude: 47.6062, Owner: "Daniel Green", "Annual Cost": 3500, "CO2 Emissions": 900 },
  { id: 8, name: "Razer Kraken X", city: "Houston", state: "TX", longitude: -95.3698, latitude: 29.7604, Owner: "Sophia Wilson", "Annual Cost": 1000, "CO2 Emissions": 250 },
  { id: 9, name: "Apple iPhone 15 Pro", city: "Boston", state: "MA", longitude: -71.0589, latitude: 42.3601, Owner: "William Anderson", "Annual Cost": 1200, "CO2 Emissions": 300 },
  { id: 10, name: "Arista 7000 Series Switches", city: "Dallas", state: "TX", longitude: -96.7970, latitude: 32.7767, Owner: "Olivia Harris", "Annual Cost": 6500, "CO2 Emissions": 1500 },
  { id: 11, name: "Corsair K100 RGB", city: "Denver", state: "CO", longitude: -104.9903, latitude: 39.7392, Owner: "James Taylor", "Annual Cost": 1800, "CO2 Emissions": 400 },
  { id: 12, name: "Google Pixel 8 Pro", city: "Phoenix", state: "AZ", longitude: -112.0740, latitude: 33.4484, Owner: "Charlotte Lewis", "Annual Cost": 850, "CO2 Emissions": 210 },
  { id: 13, name: "Cisco Nexus Switches", city: "Atlanta", state: "GA", longitude: -84.3880, latitude: 33.7490, Owner: "Henry Harris", "Annual Cost": 3200, "CO2 Emissions": 850 },
  { id: 14, name: "HPE 3PAR StoreServ", city: "Portland", state: "OR", longitude: -122.6765, latitude: 45.5234, Owner: "Ava Robinson", "Annual Cost": 1300, "CO2 Emissions": 350 },
  { id: 15, name: "Dell UltraSharp U2723QE", city: "Philadelphia", state: "PA", longitude: -75.1652, latitude: 39.9526, Owner: "Liam Clark", "Annual Cost": 6200, "CO2 Emissions": 1400 },
  { id: 16, name: "Dell XPS 15", city: "San Diego", state: "CA", longitude: -117.1611, latitude: 32.7157, Owner: "Benjamin Wright", "Annual Cost": 1600, "CO2 Emissions": 380 },
  { id: 17, name: "Razer DeathAdder V3 Pro", city: "Minneapolis", state: "MN", longitude: -93.2650, latitude: 44.9778, Owner: "Isabella King", "Annual Cost": 1200, "CO2 Emissions": 300 },
  { id: 18, name: "Samsung Galaxy Tab S9 Ultra", city: "Orlando", state: "FL", longitude: -81.3792, latitude: 28.5383, Owner: "Mason Perez", "Annual Cost": 1500, "CO2 Emissions": 360 },
  { id: 19, name: "Dell EMC PowerMax", city: "Detroit", state: "MI", longitude: -83.0458, latitude: 42.3314, Owner: "Elijah Walker", "Annual Cost": 7500, "CO2 Emissions": 1700 },
  { id: 20, name: "Pure Storage FlashArray", city: "Sacramento", state: "CA", longitude: -121.4944, latitude: 38.5816, Owner: "Madison Lopez", "Annual Cost": 900, "CO2 Emissions": 220 },
  { id: 21, name: "Corsair Void RGB Elite", city: "Seattle", state: "WA", longitude: -122.3321, latitude: 47.6062, Owner: "Grace Martin", "Annual Cost": 1800, "CO2 Emissions": 450 },
  { id: 22, name: "Bose Noise Cancelling Headphones 700", city: "Chicago", state: "IL", longitude: -87.6298, latitude: 41.8781, Owner: "Alexander Green", "Annual Cost": 2300, "CO2 Emissions": 550 },
  { id: 23, name: "Nutanix AOS", city: "Houston", state: "TX", longitude: -95.3698, latitude: 29.7604, Owner: "Lily Scott", "Annual Cost": 4800, "CO2 Emissions": 1200 },
  { id: 24, name: "Razer BlackWidow V4 Pro", city: "Los Angeles", state: "CA", longitude: -118.2437, latitude: 34.0522, Owner: "John Doe", "Annual Cost": 900, "CO2 Emissions": 220 },
  { id: 25, name: "Apple AirPods Max", city: "New York", state: "NY", longitude: -74.0060, latitude: 40.7128, Owner: "Zoe Davis", "Annual Cost": 1500, "CO2 Emissions": 350 },
  { id: 26, name: "HPE Nimble Storage", city: "San Francisco", state: "CA", longitude: -122.4194, latitude: 37.7749, Owner: "Emily Miller", "Annual Cost": 6000, "CO2 Emissions": 1500 },
  { id: 27, name: "Apple AirPods Pro (2nd Gen)", city: "Austin", state: "TX", longitude: -97.7431, latitude: 30.2672, Owner: "Samuel Edwards", "Annual Cost": 1800, "CO2 Emissions": 400 },
  { id: 27, name: "Apple AirPods Pro (2nd Gen)", city: "Austin", state: "TX", longitude: -97.7431, latitude: 30.2672, Owner: "Jacob Davis", "Annual Cost": 350, "CO2 Emissions": 100 },
  { id: 28, name: "Google Pixel Buds Pro", city: "Los Angeles", state: "CA", longitude: -118.2437, latitude: 34.0522, Owner: "Madison Moore", "Annual Cost": 400, "CO2 Emissions": 120 },
  { id: 29, name: "Bose QuietComfort 35 II", city: "San Francisco", state: "CA", longitude: -122.4194, latitude: 37.7749, Owner: "Lucas Adams", "Annual Cost": 500, "CO2 Emissions": 150 },
  { id: 30, name: "Sony WH-1000XM5", city: "Chicago", state: "IL", longitude: -87.6298, latitude: 41.8781, Owner: "Emma Green", "Annual Cost": 600, "CO2 Emissions": 180 },
  { id: 31, name: "Razer BlackWidow V3", city: "New York", state: "NY", longitude: -74.0060, latitude: 40.7128, Owner: "Ethan White", "Annual Cost": 1200, "CO2 Emissions": 320 },
  { id: 32, name: "SteelSeries Apex Pro", city: "Seattle", state: "WA", longitude: -122.3321, latitude: 47.6062, Owner: "Sophie Wright", "Annual Cost": 1400, "CO2 Emissions": 350 },
  { id: 33, name: "Corsair K95 RGB Platinum", city: "San Francisco", state: "CA", longitude: -122.4194, latitude: 37.7749, Owner: "Olivia Walker", "Annual Cost": 1600, "CO2 Emissions": 420 },
  { id: 34, name: "Logitech G915 TKL", city: "Dallas", state: "TX", longitude: -96.7970, latitude: 32.7767, Owner: "Aiden Carter", "Annual Cost": 1100, "CO2 Emissions": 280 },
  { id: 35, name: "HyperX Alloy FPS Pro", city: "Austin", state: "TX", longitude: -97.7431, latitude: 30.2672, Owner: "Isla Bennett", "Annual Cost": 800, "CO2 Emissions": 200 },
  { id: 36, name: "Razer Naga X", city: "Phoenix", state: "AZ", longitude: -112.0740, latitude: 33.4484, Owner: "Grace Brown", "Annual Cost": 700, "CO2 Emissions": 150 },
  { id: 37, name: "Logitech MX Master 3", city: "Miami", state: "FL", longitude: -80.1918, latitude: 25.7617, Owner: "Ryan Scott", "Annual Cost": 600, "CO2 Emissions": 130 },
  { id: 38, name: "Microsoft Surface Arc Mouse", city: "Philadelphia", state: "PA", longitude: -75.1652, latitude: 39.9526, Owner: "Ava Taylor", "Annual Cost": 500, "CO2 Emissions": 120 },
  { id: 39, name: "Apple Magic Mouse 2", city: "Portland", state: "OR", longitude: -122.6765, latitude: 45.5234, Owner: "Mason Clark", "Annual Cost": 400, "CO2 Emissions": 100 },
  { id: 40, name: "Logitech G Pro X Superlight", city: "Chicago", state: "IL", longitude: -87.6298, latitude: 41.8781, Owner: "Charlotte Moore", "Annual Cost": 500, "CO2 Emissions": 120 },
  { id: 41, name: "Bose QuietComfort 45", city: "London", state: "England", longitude: -0.1276, latitude: 51.5074, Owner: "Oliver Smith", "Annual Cost": 350, "CO2 Emissions": 100 },
  { id: 42, name: "Sony WH-1000XM4", city: "Paris", state: "France", longitude: 2.3522, latitude: 48.8566, Owner: "Charlotte Lefevre", "Annual Cost": 400, "CO2 Emissions": 110 },
  { id: 43, name: "Jabra Elite 85h", city: "Berlin", state: "Germany", longitude: 13.4050, latitude: 52.5200, Owner: "Max Müller", "Annual Cost": 450, "CO2 Emissions": 120 },
  { id: 44, name: "Beats Studio3 Wireless", city: "Rome", state: "Italy", longitude: 12.4964, latitude: 41.9028, Owner: "Luca Rossi", "Annual Cost": 500, "CO2 Emissions": 130 },
  { id: 45, name: "Sennheiser Momentum 3", city: "Madrid", state: "Spain", longitude: -3.7038, latitude: 40.4168, Owner: "Ana García", "Annual Cost": 550, "CO2 Emissions": 140 },
  { id: 46, name: "Bang & Olufsen Beoplay H9", city: "Amsterdam", state: "Netherlands", longitude: 4.9041, latitude: 52.3676, Owner: "Eveline de Vries", "Annual Cost": 600, "CO2 Emissions": 150 },
  { id: 47, name: "Bowers & Wilkins PX7", city: "Vienna", state: "Austria", longitude: 16.3738, latitude: 48.2082, Owner: "Johann Schmidt", "Annual Cost": 650, "CO2 Emissions": 160 },
  { id: 48, name: "Marshall Monitor II ANC", city: "Brussels", state: "Belgium", longitude: 4.3499, latitude: 50.8503, Owner: "Emma Dupont", "Annual Cost": 700, "CO2 Emissions": 170 },
  { id: 49, name: "Harman Kardon Fly ANC", city: "Copenhagen", state: "Denmark", longitude: 12.5683, latitude: 55.6761, Owner: "Sofie Nielsen", "Annual Cost": 750, "CO2 Emissions": 180 },
  { id: 50, name: "AKG N700NC M2", city: "Stockholm", state: "Sweden", longitude: 18.0686, latitude: 59.3293, Owner: "Frederik Johansson", "Annual Cost": 800, "CO2 Emissions": 190 },
  { id: 51, name: "Sony WH-1000XM3", city: "Oslo", state: "Norway", longitude: 10.7522, latitude: 59.9139, Owner: "Ida Olsen", "Annual Cost": 850, "CO2 Emissions": 200 },
  { id: 52, name: "JBL Club One", city: "Zurich", state: "Switzerland", longitude: 8.5417, latitude: 47.3769, Owner: "Nina Steiner", "Annual Cost": 900, "CO2 Emissions": 210 },
  { id: 53, name: "Sennheiser HD 450BT", city: "Helsinki", state: "Finland", longitude: 24.9384, latitude: 60.1695, Owner: "Leo Virtanen", "Annual Cost": 950, "CO2 Emissions": 220 },
  { id: 54, name: "Beats Flex", city: "Lisbon", state: "Portugal", longitude: -9.1395, latitude: 38.7169, Owner: "Maria Silva", "Annual Cost": 300, "CO2 Emissions": 90 },
  { id: 55, name: "Bose Noise Cancelling Headphones 700", city: "Madrid", state: "Spain", longitude: -3.7038, latitude: 40.4168, Owner: "Carlos Perez", "Annual Cost": 1200, "CO2 Emissions": 300 },
  { id: 56, name: "Bang & Olufsen Beoplay H8i", city: "Paris", state: "France", longitude: 2.3522, latitude: 48.8566, Owner: "Julie Martin", "Annual Cost": 1300, "CO2 Emissions": 310 },
  { id: 57, name: "Jabra Elite 65t", city: "London", state: "England", longitude: -0.1276, latitude: 51.5074, Owner: "James Brown", "Annual Cost": 350, "CO2 Emissions": 100 },
  { id: 58, name: "Sennheiser PXC 550-II", city: "Rome", state: "Italy", longitude: 12.4964, latitude: 41.9028, Owner: "Francesca Esposito", "Annual Cost": 400, "CO2 Emissions": 110 },
  { id: 59, name: "Sony MDR-1A", city: "Berlin", state: "Germany", longitude: 13.4050, latitude: 52.5200, Owner: "Oliver Lang", "Annual Cost": 450, "CO2 Emissions": 120 },
  { id: 60, name: "Bose SoundSport Free", city: "Vienna", state: "Austria", longitude: 16.3738, latitude: 48.2082, Owner: "Anna Müller", "Annual Cost": 500, "CO2 Emissions": 130 },
  { id: 61, name: "Marshall Mid ANC", city: "Amsterdam", state: "Netherlands", longitude: 4.9041, latitude: 52.3676, Owner: "Tom de Boer", "Annual Cost": 550, "CO2 Emissions": 140 },
  { id: 62, name: "Audio-Technica ATH-M50x", city: "Brussels", state: "Belgium", longitude: 4.3499, latitude: 50.8503, Owner: "Amandine Dubois", "Annual Cost": 600, "CO2 Emissions": 150 },
  { id: 63, name: "Razer Kraken Ultimate", city: "Stockholm", state: "Sweden", longitude: 18.0686, latitude: 59.3293, Owner: "Emil Karlsson", "Annual Cost": 650, "CO2 Emissions": 160 },
  { id: 64, name: "Corsair HS70 Pro", city: "Zurich", state: "Switzerland", longitude: 8.5417, latitude: 47.3769, Owner: "Sophie Meier", "Annual Cost": 700, "CO2 Emissions": 170 },
  { id: 65, name: "Logitech G Pro X", city: "Copenhagen", state: "Denmark", longitude: 12.5683, latitude: 55.6761, Owner: "Andreas Jørgensen", "Annual Cost": 750, "CO2 Emissions": 180 },
  { id: 66, name: "SteelSeries Arctis 7", city: "Oslo", state: "Norway", longitude: 10.7522, latitude: 59.9139, Owner: "Kristine Nygaard", "Annual Cost": 800, "CO2 Emissions": 190 },
  { id: 67, name: "Logitech G533", city: "Lisbon", state: "Portugal", longitude: -9.1395, latitude: 38.7169, Owner: "Ricardo Almeida", "Annual Cost": 850, "CO2 Emissions": 200 },
  { id: 68, name: "Creative Sound BlasterX H7", city: "Helsinki", state: "Finland", longitude: 24.9384, latitude: 60.1695, Owner: "Henrik Lahti", "Annual Cost": 900, "CO2 Emissions": 210 },
  { id: 69, name: "Bose QuietComfort 45", city: "Tokyo", state: "Japan", longitude: 139.6917, latitude: 35.6762, Owner: "Hiroshi Tanaka", "Annual Cost": 350, "CO2 Emissions": 100 },
  { id: 70, name: "Sony WH-1000XM4", city: "Seoul", state: "South Korea", longitude: 126.9780, latitude: 37.5665, Owner: "Jin Park", "Annual Cost": 400, "CO2 Emissions": 110 },
  { id: 71, name: "Jabra Elite 85h", city: "Singapore", state: "Singapore", longitude: 103.8198, latitude: 1.3521, Owner: "Aisha Lee", "Annual Cost": 450, "CO2 Emissions": 120 },
  { id: 72, name: "Beats Studio3 Wireless", city: "Bangkok", state: "Thailand", longitude: 100.5170, latitude: 13.7563, Owner: "Nattawut Preechaphongs", "Annual Cost": 500, "CO2 Emissions": 130 },
  { id: 73, name: "Sennheiser Momentum 3", city: "Hong Kong", state: "China", longitude: 114.1694, latitude: 22.3193, Owner: "Ming Zhao", "Annual Cost": 550, "CO2 Emissions": 140 },
  { id: 74, name: "Bang & Olufsen Beoplay H9", city: "Shanghai", state: "China", longitude: 121.4737, latitude: 31.2304, Owner: "Li Wei", "Annual Cost": 600, "CO2 Emissions": 150 },
  { id: 75, name: "Bowers & Wilkins PX7", city: "Mumbai", state: "India", longitude: 72.8777, latitude: 19.0760, Owner: "Rajesh Sharma", "Annual Cost": 650, "CO2 Emissions": 160 },
  { id: 76, name: "Marshall Monitor II ANC", city: "Delhi", state: "India", longitude: 77.1025, latitude: 28.6139, Owner: "Sanya Gupta", "Annual Cost": 700, "CO2 Emissions": 170 },
  { id: 77, name: "Harman Kardon Fly ANC", city: "Kuala Lumpur", state: "Malaysia", longitude: 101.6869, latitude: 3.1390, Owner: "Aizat Yusof", "Annual Cost": 750, "CO2 Emissions": 180 },
  { id: 78, name: "AKG N700NC M2", city: "Jakarta", state: "Indonesia", longitude: 106.8650, latitude: -6.2088, Owner: "Lina Andriani", "Annual Cost": 800, "CO2 Emissions": 190 },
  { id: 79, name: "Sony WH-1000XM3", city: "Manila", state: "Philippines", longitude: 120.9842, latitude: 14.5995, Owner: "Carlos Villanueva", "Annual Cost": 850, "CO2 Emissions": 200 },
  { id: 80, name: "JBL Club One", city: "Taipei", state: "Taiwan", longitude: 121.5654, latitude: 25.0329, Owner: "Yuan Lin", "Annual Cost": 900, "CO2 Emissions": 210 },
  { id: 81, name: "Sennheiser HD 450BT", city: "Seoul", state: "South Korea", longitude: 126.9780, latitude: 37.5665, Owner: "Yuna Kim", "Annual Cost": 950, "CO2 Emissions": 220 },
  { id: 82, name: "Beats Flex", city: "Hong Kong", state: "China", longitude: 114.1694, latitude: 22.3193, Owner: "James Chan", "Annual Cost": 300, "CO2 Emissions": 90 },
  { id: 83, name: "Bose Noise Cancelling Headphones 700", city: "Singapore", state: "Singapore", longitude: 103.8198, latitude: 1.3521, Owner: "Siti Khadijah", "Annual Cost": 1200, "CO2 Emissions": 300 },
  { id: 84, name: "Bang & Olufsen Beoplay H8i", city: "Tokyo", state: "Japan", longitude: 139.6917, latitude: 35.6762, Owner: "Takashi Nakamura", "Annual Cost": 1300, "CO2 Emissions": 310 },
  { id: 85, name: "Jabra Elite 65t", city: "Bangkok", state: "Thailand", longitude: 100.5170, latitude: 13.7563, Owner: "Somsak Pothi", "Annual Cost": 350, "CO2 Emissions": 100 },
  { id: 86, name: "Sennheiser PXC 550-II", city: "Mumbai", state: "India", longitude: 72.8777, latitude: 19.0760, Owner: "Madhuri Desai", "Annual Cost": 400, "CO2 Emissions": 110 },
  { id: 87, name: "Sony MDR-1A", city: "Jakarta", state: "Indonesia", longitude: 106.8650, latitude: -6.2088, Owner: "Sukarno Budi", "Annual Cost": 450, "CO2 Emissions": 120 },
  { id: 88, name: "Bose SoundSport Free", city: "Seoul", state: "South Korea", longitude: 126.9780, latitude: 37.5665, Owner: "Jihoon Kim", "Annual Cost": 500, "CO2 Emissions": 130 },
  { id: 89, name: "Marshall Mid ANC", city: "Taipei", state: "Taiwan", longitude: 121.5654, latitude: 25.0329, Owner: "Yong Lee", "Annual Cost": 550, "CO2 Emissions": 140 },
  { id: 90, name: "Audio-Technica ATH-M50x", city: "Manila", state: "Philippines", longitude: 120.9842, latitude: 14.5995, Owner: "Lori Tan", "Annual Cost": 600, "CO2 Emissions": 150 },
  { id: 91, name: "Razer Kraken Ultimate", city: "Shanghai", state: "China", longitude: 121.4737, latitude: 31.2304, Owner: "Jing Wang", "Annual Cost": 650, "CO2 Emissions": 160 },
  { id: 92, name: "Corsair HS70 Pro", city: "Singapore", state: "Singapore", longitude: 103.8198, latitude: 1.3521, Owner: "Victor Ong", "Annual Cost": 700, "CO2 Emissions": 170 },
  { id: 93, name: "Logitech G Pro X", city: "Hong Kong", state: "China", longitude: 114.1694, latitude: 22.3193, Owner: "Cynthia Yu", "Annual Cost": 750, "CO2 Emissions": 180 },
  { id: 94, name: "SteelSeries Arctis 7", city: "Jakarta", state: "Indonesia", longitude: 106.8650, latitude: -6.2088, Owner: "Iwan Santoso", "Annual Cost": 800, "CO2 Emissions": 190 },
  { id: 95, name: "Logitech G533", city: "Seoul", state: "South Korea", longitude: 126.9780, latitude: 37.5665, Owner: "Sung Ji", "Annual Cost": 850, "CO2 Emissions": 200 },
  { id: 96, name: "Creative Sound BlasterX H7", city: "Manila", state: "Philippines", longitude: 120.9842, latitude: 14.5995, Owner: "Paolo Sanchez", "Annual Cost": 900, "CO2 Emissions": 210 },
  { id: 97, name: "Bose QuietComfort 45", city: "Mexico City", state: "Mexico", longitude: -99.1332, latitude: 19.4326, Owner: "Carlos Rivera", "Annual Cost": 350, "CO2 Emissions": 100 },
  { id: 98, name: "Sony WH-1000XM4", city: "Guatemala City", state: "Guatemala", longitude: -90.5346, latitude: 14.6349, Owner: "Maria Lopez", "Annual Cost": 400, "CO2 Emissions": 110 },
  { id: 99, name: "Jabra Elite 85h", city: "San Salvador", state: "El Salvador", longitude: -89.2182, latitude: 13.6929, Owner: "Luis Hernandez", "Annual Cost": 450, "CO2 Emissions": 120 },
  { id: 100, name: "Beats Studio3 Wireless", city: "Panama City", state: "Panama", longitude: -79.5199, latitude: 8.9824, Owner: "Ana Rodriguez", "Annual Cost": 500, "CO2 Emissions": 130 },
  { id: 101, name: "Sennheiser Momentum 3", city: "Bogotá", state: "Colombia", longitude: -74.0721, latitude: 4.7110, Owner: "Juan Martinez", "Annual Cost": 550, "CO2 Emissions": 140 },
  { id: 102, name: "Bang & Olufsen Beoplay H9", city: "Lima", state: "Peru", longitude: -77.0369, latitude: -12.0464, Owner: "Ricardo Sánchez", "Annual Cost": 600, "CO2 Emissions": 150 },
  { id: 103, name: "Bowers & Wilkins PX7", city: "Santiago", state: "Chile", longitude: -70.6483, latitude: -33.4489, Owner: "Valentina Silva", "Annual Cost": 650, "CO2 Emissions": 160 },
  { id: 104, name: "Marshall Monitor II ANC", city: "Buenos Aires", state: "Argentina", longitude: -58.4438, latitude: -34.6037, Owner: "Fernando Alvarez", "Annual Cost": 700, "CO2 Emissions": 170 },
  { id: 105, name: "Harman Kardon Fly ANC", city: "Rio de Janeiro", state: "Brazil", longitude: -43.1729, latitude: -22.9068, Owner: "Pedro Costa", "Annual Cost": 750, "CO2 Emissions": 180 },
  { id: 106, name: "AKG N700NC M2", city: "São Paulo", state: "Brazil", longitude: -46.6333, latitude: -23.5505, Owner: "Beatriz Oliveira", "Annual Cost": 800, "CO2 Emissions": 190 },
  { id: 107, name: "Sony WH-1000XM3", city: "Montevideo", state: "Uruguay", longitude: -56.1645, latitude: -34.9011, Owner: "Carlos Gómez", "Annual Cost": 850, "CO2 Emissions": 200 },
  { id: 108, name: "JBL Club One", city: "Asunción", state: "Paraguay", longitude: -57.5759, latitude: -25.2637, Owner: "Lorena Benitez", "Annual Cost": 900, "CO2 Emissions": 210 },
  { id: 109, name: "Sennheiser HD 450BT", city: "Quito", state: "Ecuador", longitude: -78.4678, latitude: -0.1807, Owner: "Julio Pérez", "Annual Cost": 950, "CO2 Emissions": 220 },
  { id: 110, name: "Beats Flex", city: "La Paz", state: "Bolivia", longitude: -68.1193, latitude: -16.5000, Owner: "Marco Flores", "Annual Cost": 300, "CO2 Emissions": 90 },
  { id: 111, name: "Bose Noise Cancelling Headphones 700", city: "Caracas", state: "Venezuela", longitude: -66.9036, latitude: 10.4806, Owner: "Isabella Rodríguez", "Annual Cost": 1200, "CO2 Emissions": 300 },
  { id: 112, name: "Jabra Elite 65t", city: "Lima", state: "Peru", longitude: -77.0369, latitude: -12.0464, Owner: "Eduardo Torres", "Annual Cost": 350, "CO2 Emissions": 100 },
  { id: 113, name: "Sennheiser PXC 550-II", city: "Santiago", state: "Chile", longitude: -70.6483, latitude: -33.4489, Owner: "Mariana González", "Annual Cost": 400, "CO2 Emissions": 110 },
  { id: 114, name: "Sony MDR-1A", city: "São Paulo", state: "Brazil", longitude: -46.6333, latitude: -23.5505, Owner: "Lucas Carvalho", "Annual Cost": 450, "CO2 Emissions": 120 },
  { id: 115, name: "Bose SoundSport Free", city: "Panama City", state: "Panama", longitude: -79.5199, latitude: 8.9824, Owner: "Adriana Pérez", "Annual Cost": 500, "CO2 Emissions": 130 },
  { id: 116, name: "Marshall Mid ANC", city: "Mexico City", state: "Mexico", longitude: -99.1332, latitude: 19.4326, Owner: "José Gómez", "Annual Cost": 550, "CO2 Emissions": 140 },
  { id: 117, name: "Audio-Technica ATH-M50x", city: "San Salvador", state: "El Salvador", longitude: -89.2182, latitude: 13.6929, Owner: "Ana Martínez", "Annual Cost": 600, "CO2 Emissions": 150 },
  { id: 118, name: "Razer Kraken Ultimate", city: "Quito", state: "Ecuador", longitude: -78.4678, latitude: -0.1807, Owner: "Diego López", "Annual Cost": 650, "CO2 Emissions": 160 },
  { id: 119, name: "Corsair HS70 Pro", city: "Rio de Janeiro", state: "Brazil", longitude: -43.1729, latitude: -22.9068, Owner: "Paula Dias", "Annual Cost": 700, "CO2 Emissions": 170 },
  { id: 120, name: "Logitech G Pro X", city: "Bogotá", state: "Colombia", longitude: -74.0721, latitude: 4.7110, Owner: "Santiago Pérez", "Annual Cost": 750, "CO2 Emissions": 180 },
  { id: 121, name: "SteelSeries Arctis 7", city: "Buenos Aires", state: "Argentina", longitude: -58.4438, latitude: -34.6037, Owner: "Carlos Hernández", "Annual Cost": 800, "CO2 Emissions": 190 },
  { id: 122, name: "Logitech G533", city: "Caracas", state: "Venezuela", longitude: -66.9036, latitude: 10.4806, Owner: "Ricardo Pérez", "Annual Cost": 850, "CO2 Emissions": 200 },
  { id: 123, name: "Creative Sound BlasterX H7", city: "Mexico City", state: "Mexico", longitude: -99.1332, latitude: 19.4326, Owner: "Jessica Rodríguez", "Annual Cost": 900, "CO2 Emissions": 210 },
  { id: 124, name: "Corsair HS50 Pro", city: "Asunción", state: "Paraguay", longitude: -57.5759, latitude: -25.2637, Owner: "Carlos Duarte", "Annual Cost": 950, "CO2 Emissions": 220 }
];



const MapComponent = ({contextApi}) => {

  const mode = useSelector(state => state.environment.mode);
  const dispatch = useDispatch();
  const [hoveredCenter, setHoveredCenter] = useState(null);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isDivHovered, setIsDivHovered] = useState(false); // To keep track if the div is hovered

  const handleMarkerHover = (center, e) => {
    setHoveredCenter(center);
    setCursorPosition({ x: e.originalEvent.clientX, y: e.originalEvent.clientY });
  };

  const handleMouseOut = () => {
    if (!isDivHovered) {
      setHoveredCenter(null); // Only hide the div if it's not hovered
    }
  };

  const goToCenter = (page) => {
    if(page==="Home" || page==="Insights"){
      dispatch(setCurrentPage(page));
    }
   
  };

  return (
    <div className={`flex w-full h-4/5 bg-mode-${mode} primary-text-${mode}`}>
    <MapContainer
      center={[0, 0]} // Centered over the United States
      zoom={2.5} // Zoom level closer to the US
      className="flex flex-col h-100 w-full"
      style={{ height: "100vh", width: "100%" }} // Ensuring the map fills the view
    >
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />

      {oomnieiqs.map((center) => (
        <Marker
          key={center.id}
          position={[center.latitude, center.longitude]}
          icon={markerIcon}
          className="cursor-pointer"
          eventHandlers={{
            mouseover: (e) => handleMarkerHover(center, e), // Capture mouse position on hover
            mouseout: handleMouseOut, // Hide div on mouse out, unless hovering over the div
          }}
        />
      ))}

      {hoveredCenter && (
        <div
          style={{
            position: 'absolute',
            top: `${cursorPosition.y}px`, // Use cursor's y-position
            left: `${cursorPosition.x}px`, // Use cursor's x-position
            transform: 'translate(-50%, -100%)', // Move the div above and centered at the cursor
            zIndex: 1000,
            pointerEvents: 'auto', // Ensure the div can be interacted with
          }}
          className={`flex flex-col p-2 rounded-md shadow-lg  overflow-hidden justify-start bg-mode-${mode} border-mode-${mode} primary-text-${mode} panel-mode-${mode} cursor-pointer fade-in`}
          onMouseEnter={() => setIsDivHovered(true)} // Keep the div visible when hovered
          onMouseLeave={() => {
            setIsDivHovered(false);
            setHoveredCenter(null); // Hide the div when the mouse leaves it
          }}
        >
          <h6>{hoveredCenter.name}</h6>
          {Object.entries(hoveredCenter).map(([key, value], i) => (
            <div className={`ms-2 font-bold secondary-color-mode-${mode} `} key={i}>
              {key}: <span className={`ms-2 font-bold primary-color-mode-${mode} `}>{value}</span>
            </div>
          ))}
          <div className={`flex w-full p-2 justify-between border-t-[1px] mt-2 border-t-[${mode ?"rgb(50,50,50)" : "rgb(200,200,200)]"}`}>
            <button className={`button-mode-${mode} hover:cursor-pointer m-1`} onClick={() => goToCenter("Home")}>Details</button>
            <button className={`button-mode-${mode} hover: cursor-pointer m-1`} onClick={() => goToCenter("Insights")}>Insights</button>
          </div>
        </div>
      )}
    </MapContainer>
    </div>
  );
};

export default MapComponent;
