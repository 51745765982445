import React, { useRef, useLayoutEffect, useState } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import Modal from 'react-modal'; // Ensure you have react-modal installed

const data = {
  nodes: [
    { id: 'Avik Ghosh', group: 'Employee'},
    { id: 'Donna Wilczek', group: 'Employee'},
    { id: 'Ramin Ettehad', group: 'Employee'},
    { id: 'Laptop1', group: 'Hardware'},
    { id: 'Laptop2', group: 'Hardware' },
    { id: 'Laptop3', group: 'Hardware'},
    { id: 'Software1', group: 'Software' },
    { id: 'Software2', group: 'Software' },
    { id: 'Software3', group: 'Software' },
    { id: 'Phone1', group: 'Hardware' },
    { id: 'Phone2', group: 'Hardware' },
    { id: 'App1', group: 'App' },
    { id: 'App2', group: 'App'}
  ],
  links: [
    { source: 'Avik Ghosh', target: 'Laptop1' },
    { source: 'Donna Wilczek', target: 'Laptop2' },
    { source: 'Ramin Ettehad', target: 'Phone1' },
    { source: 'Ramin Ettehad', target: 'Laptop3' },
    { source: 'Ramin Ettehad', target: 'Phone2' },
    { source: 'Laptop1', target: 'Software1' },
    { source: 'Laptop2', target: 'Software1' },
    { source: 'Laptop2', target: 'Software2' },
    { source: 'Laptop3', target: 'Software1' },
    { source: 'Laptop3', target: 'Software2' },
    { source: 'Laptop3', target: 'Software3' },
    { source: 'Phone1', target: 'App1' },
    { source: 'Phone1', target: 'App2' }
  ]
};

const NetworkGraph2d = (props) => {
  const mode = props.mode
  const fgRef = useRef();  // Reference to ForceGraph2D
  const containerRef = useRef(); // Reference to the container div
  const [dimensions, setDimensions] = useState({ width: 2000, height: 800 });

  // Formulaically determine zoom based on number of nodes and container size
  const calculateZoomLevel = () => {
    const nodeCount = data.nodes.length;
    // const baseZoom = Math.max(1, Math.min(2, 100 / nodeCount));  // Adjust zoom based on node count
    const baseZoom =3
    return baseZoom;
  };

  // Customize node appearance
  const nodePaint = (node, ctx, globalScale) => {
    const label = node.id;
    const fontSize = 12 / globalScale;
    ctx.font = `${fontSize}px Sans-Serif`;

    ctx.fillStyle = node.group === 'Employee' ? 'rgb(0,180,225)' : node.group === 'Hardware' ? 'rgb(0,225,180)' : 'rgb(180,100,225)';
    ctx.beginPath();
    ctx.arc(node.x, node.y, 8, 0, 2 * Math.PI, false);
    ctx.fill();

    ctx.fillStyle = 'gray';
    ctx.fillText(label, node.x + 10, node.y + 4);
  };

  // Apply the dynamic zoom once the graph is rendered
  useLayoutEffect(() => {
    if (fgRef.current) {
      const zoomLevel = calculateZoomLevel();  // Get dynamic zoom level
      fgRef.current.zoom(zoomLevel);  // Apply zoom
    }
  }, [dimensions]);  // Run zoom calculation when dimensions are updated

  // Ensure the graph fits the container initially and on resize
  useLayoutEffect(() => {
    const updateDimensions = () => {
      const width = containerRef.current.clientWidth;
      const height = containerRef.current.clientHeight;
      setDimensions({ width, height });
    };

    window.addEventListener('resize', updateDimensions);
    updateDimensions();  // Set dimensions on mount

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);


  const [selectedNode, setSelectedNode] = useState(null); // State for the selected node
  const [isModalOpen, setIsModalOpen] = useState(false); // State for modal visibility

  // Handle node click
  const handleNodeClick = (node) => {
    setSelectedNode(node); // Set the selected node
    setIsModalOpen(true); // Open the modal
  };

  // Function to close the modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNode(null); // Clear the selected node
  };

  return (
    <div ref={containerRef} className={`flex w-full h-[800px] rounded-md`}>
      <ForceGraph2D
        ref={fgRef}
        graphData={data}
        nodeCanvasObject={nodePaint}
        linkDirectionalParticles={2}
        linkDirectionalParticleSpeed={d => 0.004}
        width={dimensions.width}
        height={dimensions.height}
        linkColor={link => {
          if (link.source.group === 'Employee') return 'rgb(0,180,225)';
          if (link.source.group === 'Hardware') return 'rgb(0,225,180)';
          return 'gray';
        }}
        onNodeClick={handleNodeClick} // Attach the click handler
      />
       {/* Modal for displaying node information */}
       <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Node Information"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
          },
          content: {
            top: '0',
            left: '0',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: 'translate(-100%, -100%)',
            width: '100px',
            height: '100px',
          },
        }}
      >
        <h2>Aseet Summary</h2>
        {selectedNode && (
          <div>
            <p><strong>ID:</strong> {selectedNode.id}</p>
            <p><strong>Group:</strong> {selectedNode.group}</p>
          </div>
        )}
        <button className={`button-mode-${mode}`} onClick={closeModal}>Close</button>
      </Modal>
    </div>
  );
};

export default NetworkGraph2d;


