import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import * as oomnielabsApi from '../../apis/oomnielabs.js'

import Header from "./components/Header.js";
import Summary from "./components/Summary.js";
import Financials from './components/Financials.js';
import Lifecycle from './components/Lifecycle.js';
import Relationships from "./components/Relationships.js";
import Locations from "./components/MapView.js";
import Compliance from './components/Compliance.js';
import AILab from './components/AILab.js'
import Navbar from './components/Navbar.js';

// Set application details:
export const appName = "OonnieIQ"
export const logoFile = "Oomnitza_logo.png"
 
function App({contextApi}) {

  const mode = useSelector(state => state.environment.mode);
  const currentPage = useSelector(state => state.oomnieInsightsGenie.currentPage);
  console.log(currentPage)

  return (
    <div className={`flex flex-col w-full h-[100vh] overflow-hidden transition duration-500 body-mode-${mode} `}>
        <Navbar contextApi={contextApi}/>
        {currentPage ==="summary" && <Summary contextApi={contextApi} />}
        {currentPage ==="lifecycle" && <Lifecycle contextApi={contextApi}/>}
        {currentPage ==="locations" && <Locations contextApi={contextApi}/>}
        {currentPage ==="financials" && <Financials contextApi={contextApi} />}
        {currentPage ==="relationships" && <Relationships contextApi={contextApi}/>}
        {currentPage ==="ai_lab" && <AILab contextApi={contextApi}/>}
    </div>
  );
}

export default App;

