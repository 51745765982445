import React, { useState } from 'react';
import {useSelector} from 'react-redux'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import '../kanban.css';

// Define lifecycle stages
const stages = [
  {id: 1, name: 'Ordered', color: "rgba(255, 255, 153,1)"},
  {id: 2, name: 'Receiving', color: "rgba(255, 200, 124,1)"},
  {id: 3, name: 'In Inventory', color: "rgba(144, 238, 144,1)"},
  {id: 4, name: 'Provisioning', color: "rgba(173, 216, 230,1)"},
  {id: 5, name: 'Deployed', color: "rgba(204, 153, 255,1)"},
  {id: 6, name: 'Maintenance', color: "rgba(200, 200, 200,1)"},
  {id: 7, name: 'Decommissioned', color: "rgba(255, 182, 193,1)"}
];

const Kanban = (props) => {
  const mode = useSelector(state=>state.environment.mode)
  const [assets, setAssets] = useState(props.assets);

  // Function to reorder assets when dragged
  const handleOnDragEnd = (result) => {
    const { destination, source } = result;

    if (!destination) return; // Dropped outside the board

    if (
      source.droppableId === destination.droppableId &&
      source.index === destination.index
    ) {
      return; // No movement
    }

    const updatedAssets = Array.from(assets);
    const [movedAsset] = updatedAssets.splice(source.index, 1);
    movedAsset.stage = destination.droppableId;

    updatedAssets.splice(destination.index, 0, movedAsset);
    setAssets(updatedAssets); // Update state
  };

  // Group assets by lifecycle stage
  const groupedAssets = stages.reduce((acc, stage) => {
    acc[stage] = assets.filter((asset) => asset.stage === stage.name);
    return acc;
  }, {});

  return (
    <div className={`kanban-board-mode-${mode}`}>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        {stages.map((stage) => (
          <div key={stage.id} 
            className={`kanban-column-mode-${mode}`}
            style={{backgroundColor: stage.color}}
            >
            <h3>{stage.name}</h3>
            <Droppable droppableId={stage.id}>
              {(provided) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`kanban-column-cards-mode-${mode}`}
                >
                  {groupedAssets[stage].map((asset, index) => (
                    <Draggable key={asset.id} draggableId={asset.id} index={index}>
                      {(provided) => (
                        <div
                          className={`kanban-card-mode-${mode}`}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <div className="font-bold text-[16px] m-1">{asset.name}</div>
                          
                          <div className="m-1">
                            <span className="text-gray-500">Category: </span>
                            <span className="font-bold">{`${asset.asset_category}`}</span>
                          </div>
                          <div className="m-1">
                            <span className="text-gray-500">Status: </span>
                            <span className="font-bold">{`${asset.status}`}</span>
                          </div>
                          <div className="m-1">
                            <span className="text-gray-500">Remaning LifeCycle: </span>
                            <span className="font-bold">{`${asset.remaining_lifecycle}%`}</span>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </div>
        ))}
      </DragDropContext>
    </div>
  );
};

export default Kanban;


