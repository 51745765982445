import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';

import * as oomnielabsApi from '../../../apis/oomnielabs.js'
import "react-datepicker/dist/react-datepicker.css";

import ChartFilter from './ChartFilter.js';

import DoughnutChart from "./DoughnutChart.js"
import MultiSeriesBarChart from './MultiSeriesBarChart.js';
import Svg from '../../../components/Svg.js';


const OpexCapexAnalysis = (props) => {

  const mode = useSelector(state => state.environment.mode);
  const [showFilter, setShowFilter] = useState(false)
  const [formData, setFormData] = useState({})
  const [data, setData] = useState([])

  const updateChart = async (formData)=>{
    console.log(formData)
    const app_name = "asset_analysis"
    const main_function = "run_analysis"
    const parameters = formData
    try{
      const response = await oomnielabsApi.pythonApp(app_name,main_function, parameters)
      console.log(response)
      setData(response.data)
    }catch(error){
      console.log(error)
    }
}


  return (

    <div className={`relative flex w-full h-[100%]`}>

      {/* Capex vs Opex Chart */}
      <div className={`flex flex-col w-full h-[auto] panel-mode-${mode} mb-5 rounded-md`}>
        
        {/* Chart Header */}
        <div className={`flex w-full h-[50px] items-center header-strip-mode-${mode} pe-3 justify-end`}>
          <div title={"Filter"} onClick={(e)=>setShowFilter(!showFilter)}>
            <Svg 
              iconName = {"FilterIcon"}
              height  = {30}
              width  = {30}
              fillColor  = {"rgb(150,150,150"}
              fillOpacity  = {1}
              isHovered  = {false}
              cursor  = {"pointer"}å
            />
          </div>
        </div>

        {/* Cost Body */}  
        <div className={`flex w-full h-[350px] overflow-hidden rounded-md p-3`}>
          
          {/* Doughnut Chart */}
          <div className="relative flex flex-col w-1/4 h-[300px] justify-center items-center">
            <div className="flex flex-col h-[300px] justify-center items-center">
              <DoughnutChart
                labels = {['CapEx', 'OpEx']}
                values = {[30, 70]}
                measureLabel = {"Percent of Total"}
                series1Color = {"rgba(0,150,225,0.6)"}
                series2Color = {"rgba(0,225,150,0.6)"}
                mode = {mode}
              />
            </div>

            <div className="absolute top-[50%] flex w-full flex-col justify-center">
              <div className={`flex w-full justify-center font-bold text-[28px] primary-color-mode-${mode}`}>$140M</div>
              <div className={`flex w-full justify-center text-[12px] secondary-color-mode-${mode}`}>Total Spend</div>
            </div>
          </div>

          {/* Bar Chart */}
          <div className={`flex flex-col h-[350px] w-1/2 p-3`}>
            <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">CapEx vs OpEx</div>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">CapEx has increased from 1.8x to 3x of Opex.  This exceeds your peer group average.</div>
            <div className="flex w-full h-[250px]">
              <MultiSeriesBarChart
                label = "Quarter"
                labels = {['Q1', 'Q2', 'Q3', 'Q4']}
                datasets = {[
                  {
                    label: 'Total Spend',
                    values: [50, 20, 30, 40],
                    type: 'line',
                    borderColor: 'rgba(0, 100, 150, 1)',
                    backgroundColor: 'rgba(0, 100, 150, 1)',
                    pointBackgroundColor: 'rgba(0, 100, 150, 1)'
                    
                  },
                  {
                    label: 'CapEx',
                    values: [35, 32, 28, 25],
                    backgroundColor: 'rgba(0, 150, 225, 0.6)',
                  },
                  {
                    label: 'OpEx',
                    values: [65, 68, 72, 75],
                    backgroundColor: 'rgba(0, 225, 150, 0.6)',
                  },
                ]}
                barColor = "rgba(0,200,180,0.5)"
                xAxisTitle = ""
                yAxisTitle = "Capex vs OpEx Percent of Total Spend"
                secondaryYAxisTitle = "Total Spend"
                mode = {mode}
              />
            </div>
          </div>


          {/* Forecast Chart */}
          <div className={`flex flex-col h-[350px] w-1/4 p-3`}>
            <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">Forecast</div>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">OpEx is expected be 4x of CapEx in 180 days.</div>
            <div className="flex w-full h-[300px]">
              <MultiSeriesBarChart
                label = "Period"
                labels = {['30 Days', '90 Days', '180 Days']}
                datasets = {[
                  {
                    label: 'CapEx',
                    values: [25, 23, 20],
                    backgroundColor: 'rgba(0, 150, 225, 0.6)',
                  },
                  {
                    label: 'OpEx',
                    values: [75, 77, 80],
                    backgroundColor: 'rgba(0, 225, 150, 0.6)',
                  }
                ]}
                barColor = "rgba(0,200,180,0.5)"
                yAxisTitle = "Spend (USD)"
                xAxisTitle = ""
                stackedX = {true}
                stackedY = {true}
                mode = {mode}
              />
            </div>
          </div>


        </div>

      </div>
 
      {/* Filter Menu */}
      {showFilter && 
        <ChartFilter 
          showFilter = {showFilter}
          setShowFilter = {setShowFilter}
          updateFormData = {setFormData}
          updateChart = {(formData)=>updateChart(formData)}
        />
      }

    </div>
  )
}

export default OpexCapexAnalysis
