import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';

import * as oomnielabsApi from '../../../apis/oomnielabs.js'
import "react-datepicker/dist/react-datepicker.css";

import ChartFilter from './ChartFilter.js';

import DoughnutChart from "./DoughnutChart.js"
import MultiSeriesBarChart from './MultiSeriesBarChart.js';
import Svg from '../../../components/Svg.js';


const SpendAnalysis = (props) => {

  const mode = useSelector(state => state.environment.mode);
  const [showFilter, setShowFilter] = useState(false)
  const [formData, setFormData] = useState({})

  const [data, setData] = useState([])

  const updateChart = async (formData)=>{

      console.log(formData)
      const app_name = "asset_analysis"
      const main_function = "run_analysis"
      const parameters = formData
      try{
        const response = await oomnielabsApi.pythonApp(app_name,main_function, parameters)
        console.log(response)
        setData(response.data)
      }catch(error){
        console.log(error)
      }
  }


  return (

    <div className={`relative flex w-full h-[100%]`}>

      {/* Spend vs Budget Chart */}
      <div className={`flex flex-col w-full h-[auto] panel-mode-${mode} mb-5 rounded-md`}>
        
        {/* Chart Header */}
        <div className={`flex w-full h-[50px] items-center header-strip-mode-${mode} pe-3 justify-end`}>
          <div title={"Filter"} onClick={(e)=>setShowFilter(!showFilter)}>
            <Svg 
              iconName = {"FilterIcon"}
              height  = {30}
              width  = {30}
              fillColor  = {"rgb(150,150,150"}
              fillOpacity  = {1}
              isHovered  = {false}
              cursor  = {"pointer"}å
            />
          </div>
        </div>

        {/* Chart Body */}  
        <div className={`flex w-full h-[400p] overflow-hidden rounded-md p-3`}>
          
          {/* Doughnut Chart */}
          <div className="relative flex flex-col w-1/4 h-[300px] justify-center items-center">
            <div className="flex flex-col h-[300px] justify-center items-center">
              <DoughnutChart
                labels = {['Q1', 'Q2', 'Q3', 'Q4']}
                values = {[50, 20, 30, 40]}
                mode = {mode}
              />
            </div>

            <div className="absolute top-[50%] flex w-full flex-col justify-center">
              <div className={`flex w-full justify-center font-bold text-[28px] primary-color-mode-${mode}`}>$140M</div>
              <div className={`flex w-full justify-center text-[12px] secondary-color-mode-${mode}`}>Total Spend</div>
            </div>
          </div>

          {/* Bar Chart */}
          <div className={`flex flex-col h-[350px] w-1/2 p-3`}>
            <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">Budget vs Actual Spend</div>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">Spend is exceeding budget by 21.7%</div>
            <div className="flex w-full h-[250px]">
              <MultiSeriesBarChart
                label = "Quarter"
                labels = {['Q1', 'Q2', 'Q3', 'Q4']}
                datasets = {[
                  {
                    label: 'Budget',
                    values: [25, 30, 25, 35],
                    backgroundColor: 'rgba(200, 200, 200, 0.6)',
                  },
                  {
                    label: 'Actual Spend',
                    values: [50, 20, 30, 40],
                    backgroundColor: 'rgba(255, 150, 50, 0.6)',
                  },
                ]}
                barColor = "rgba(0,200,180,0.5)"
                yAxisTitle = "Percent"
                xAxisTitle = ""
                mode = {mode}
              />
            </div>
          </div>


          {/* Forecast Chart */}
          <div className={`flex flex-col h-[350px] w-1/4 p-3`}>
            <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">Forecast</div>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2 text-orange-400">Asset spend is projected to increase by 17% over 180 days</div>
            <div className="flex w-full h-[300px]">
              <MultiSeriesBarChart
                label = "Period"
                labels = {['30 Days', '90 Days', '180 Days']}
                datasets = {[
                  {
                    label: 'CapEx',
                    values: [40, 43, 47],
                    backgroundColor: 'rgba(0, 150, 225, 0.6)',
                  },
                ]}
                barColor = "rgba(0,200,180,0.5)"
                yAxisTitle = "Spend (USD)"
                xAxisTitle = ""
                stackedX = {true}
                stackedY = {true}
                mode = {mode}
              />
            </div>
          </div>

        </div>

      </div>
 
      {/* Filter Menu */}
      {showFilter && 
        <ChartFilter 
          showFilter = {showFilter}
          setShowFilter = {setShowFilter}
          updateFormData = {setFormData}
          updateChart = {(formData)=>updateChart(formData)}
        />
      }

    </div>
  )
}

export default SpendAnalysis
