import React from 'react';
import Tree from 'react-d3-tree';

const Heirarchy = (props) =>{

  const object = props.object || null

  return (
    // `<Tree />` will fill width/height of its container; in this case `#treeWrapper`.
    <div 
        id="treeWrapper"
        className={`flex w-full h-[100%] p-3 border-[1px] justify-center items-center`}
    >
      <Tree 
        data={object} 
        orientation="vertical"
        postion
    />
    </div>
  );
}

export default Heirarchy

