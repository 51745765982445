import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';

import * as oomnielabsApi from '../../../apis/oomnielabs.js'
import "react-datepicker/dist/react-datepicker.css";

import ChartFilter from './ChartFilter.js';

import DoughnutChart from "./DoughnutChart.js"
import MultiSeriesBarChart from './MultiSeriesBarChart.js';
import Svg from '../../../components/Svg.js';


const CycleCount = (props) => {

  const mode = useSelector(state => state.environment.mode);
  const [showFilter, setShowFilter] = useState(false)
  const [formData, setFormData] = useState({})
  const [data, setData] = useState([])

  const updateChart = async (formData)=>{
    console.log(formData)
    const app_name = "asset_analysis"
    const main_function = "run_analysis"
    const parameters = formData
    try{
      const response = await oomnielabsApi.pythonApp(app_name,main_function, parameters)
      console.log(response)
      setData(response.data)
    }catch(error){
      console.log(error)
    }
}


  return (

    <div className={`relative flex w-full h-[100%]`}>

       {/* Main Chart */}
      <div className={`flex flex-col w-full h-[auto] panel-mode-${mode} mb-5 rounded-md`}>
        
        {/* Chart Header */}
        <div className={`flex w-full h-[50px] items-center header-strip-mode-${mode} pe-3 justify-end`}>
          <div title={"Filter"} onClick={(e)=>setShowFilter(!showFilter)}>
            <Svg 
              iconName = {"FilterIcon"}
              height  = {30}
              width  = {30}
              fillColor  = {"rgb(150,150,150"}
              fillOpacity  = {1}
              isHovered  = {false}
              cursor  = {"pointer"}å
            />
          </div>
        </div>


        {/* Chart Body */}  
        <div className={`flex w-full h-[400px] overflow-hidden rounded-md p-3`}>
          
          {/* Power Consumption */}
          <div className={`flex w-1/2`}>
            {/* Doughnut Chart */}
            <div className="relative flex flex-col w-1/3 h-[350px] justify-center items-center">
              <div className="flex flex-col h-[250px] justify-center items-center">
                <DoughnutChart
                labels = {['California', 'Ohio', 'Virgina', 'Oregon']}
                values = {[1.0, 0.6, 0.5, 0.4]}
                mode = {mode}
                fontSize = {12}
                />
              </div>

              <div className="absolute top-[50%] flex w-full flex-col justify-center">
                <div className={`flex w-full justify-center font-bold text-[28px] primary-color-mode-${mode}`}>2.5M</div>
                <div className={`flex w-full justify-center text-[12px] secondary-color-mode-${mode}`}>Kilowatt-Hours</div>
              </div>
            </div>

            {/* Bar Chart */}
            <div className={`flex flex-col h-[350px] w-2/3 p-3`}>
              <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">Power Consumption</div>
              <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">Power consumption has increased by 22%.</div>
              <div className="flex w-full h-[300px]">
                <MultiSeriesBarChart
                  label = "kwH"
                  labels = {['Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'July-24', 'Aug-24', 'Sep-24']}
                  datasets = {[
                    {
                      label: 'kwH',
                      values: [200, 180, 175, 175, 180, 190, 200, 220, 235, 245, 250, 245],
                      backgroundColor: "rgba(0,200,180,0.5)",
                    },
                  ]}
                   barColor = "rgba(0,200,180,0.5)"
                  xAxisTitle = ""
                  yAxisTitle = "Thousand kWh"
                  mode = {mode}
                  stackedX={true}
                  stackedY={true}
                />
              </div>
            </div>
          </div>


          {/* Carbon Emission */}
          <div className={`flex w-1/2`}>
            {/* Doughnut Chart */}
            <div className="relative flex flex-col w-1/3 h-[350px] justify-center items-center">
              <div className="flex flex-col h-[250px] justify-center items-center">
                <DoughnutChart
                   labels = {['California', 'Ohio', 'Virgina', 'Oregon']}
                   values = {[4, 3, 2, 3]}
                   mode = {mode}
                   fontSize = {12}
                />
              </div>

              <div className="absolute top-[50%] flex w-full flex-col justify-center">
                <div className={`flex w-full justify-center font-bold text-[28px] primary-color-mode-${mode}}`}>1.05</div>
                <div className={`flex w-full justify-center text-[12px] secondary-color-mode-${mode}}`}>Metric Tons CO2</div>
              </div>
            </div>

            {/* Bar Chart */}
            <div className={`flex flex-col h-[350px] w-2/3 p-3`}>
              <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">Carbon Emissions</div>
              <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">Carbon emissions has increased by 20% with power consumption.</div>
              <div className="flex w-full h-[300px]">
                <MultiSeriesBarChart
                  label = "kwH"
                  labels = {['Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'July-24', 'Aug-24', 'Sep-24']}
                  datasets = {[
                    {
                      label: "MT Co2",
                      values: [200, 180, 175, 175, 180, 190, 200, 220, 235, 245, 250, 245],
                      backgroundColor: "rgba(0,200,180,0.5)",
                    },
                  ]}
                   barColor = "rgba(0,200,180,0.5)"
                  xAxisTitle = ""
                  yAxisTitle = "MT Co2"
                  mode = {mode}
                  stackedX={true}
                  stackedY={true}
                />
              </div>
            </div>
          </div>

        </div>

      </div>
 
      {/* Filter Menu */}
      {showFilter && 
        <ChartFilter 
          showFilter = {showFilter}
          setShowFilter = {setShowFilter}
          updateFormData = {setFormData}
          updateChart = {(formData)=>updateChart(formData)}
        />
      }

    </div>
  )
}

export default CycleCount
