import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';

import * as oomnielabsApi from '../../../apis/oomnielabs.js'
import "react-datepicker/dist/react-datepicker.css";

import ChartFilter from './ChartFilter.js';

import DoughnutChart from "./DoughnutChart.js"
import MultiSeriesBarChart from './MultiSeriesBarChart.js';
import Svg from '../../../components/Svg.js';


const WorkingCapitalImpact = (props) => {

  const mode = useSelector(state => state.environment.mode);
  const [showFilter, setShowFilter] = useState(false)
  const [formData, setFormData] = useState({})
  const [data, setData] = useState([])

  const updateChart = async (formData)=>{
    console.log(formData)
    const app_name = "asset_analysis"
    const main_function = "run_analysis"
    const parameters = formData
    try{
      const response = await oomnielabsApi.pythonApp(app_name,main_function, parameters)
      console.log(response)
      setData(response.data)
    }catch(error){
      console.log(error)
    }
}


  return (

    <div className={`relative flex w-full h-[100%]`}>

      {/* Working Capital Chart */}
      <div className={`flex flex-col w-full h-[auto] panel-mode-${mode} mb-5 rounded-md`}>
        
        {/* Chart Header */}
        <div className={`flex w-full h-[50px] items-center header-strip-mode-${mode} pe-3 justify-end`}>
          <div title={"Filter"} onClick={(e)=>setShowFilter(!showFilter)}>
            <Svg 
              iconName = {"FilterIcon"}
              height  = {30}
              width  = {30}
              fillColor  = {"rgb(150,150,150"}
              fillOpacity  = {1}
              isHovered  = {false}
              cursor  = {"pointer"}å
            />
          </div>
        </div>

        {/* Cost Body */}  
        <div className={`flex w-full h-[350px] overflow-hidden rounded-md p-3`}>
          
          {/* Doughnut Chart */}
          <div className="relative flex flex-col w-[300px] h-[350px] justify-center items-center">
            <div className="flex flex-col h-[300px] justify-center items-center">
              <DoughnutChart
                labels = {['Network & Data Center', 'Cloud Computing', 'Hardware', 'Software']}
                values = {[70, 15, 10, 5]}
                measureLabel = {"Percent of Total"}
                mode = {mode}
              />
            </div>

            <div className="absolute top-[55%] flex w-full flex-col justify-center">
              <div className={`flex w-full justify-center font-bold text-[28px] primary-color-mode-${mode}`}>$15.1M</div>
              <div className={`flex w-full justify-center text-[12px] secondary-color-mode-${mode}`}>Total Value</div>
            </div>
          </div>

          {/* Bar Chart */}
          <div className={`flex flex-col h-[350px] w-1/2 p-3`}>
            <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">DIO Impact on Working Capital</div>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">Asset Value has increased by 65% while DIO has remained constant.</div>
            <div className="flex w-full h-[250px]">
              <MultiSeriesBarChart
                 label = "Month"
                 labels = {["January", "February", "March", "April", "May", "June", "July", "August", "September", "October"]}
                 datasets = {[
                   {
                     label: 'Inventory Turnover Ratio',
                     values: [5.0, 4.58, 4.0, 3.89, 4.06, 3.93, 4.0, 4.0, 3.79, 4.0],
                     type: 'line',
                     borderColor: 'rgba(0, 100, 0, 1)',
                     backgroundColor: 'rgba(0, 100, 0, 1)',
                     pointBackgroundColor: 'rgba(0, 100, 0, 1)'
                     
                   },
                   {
                    label: 'Average Inventory ($)',
                    values: [1000000, 1200000, 1500000, 1800000, 1600000, 1400000, 1300000, 1700000, 1900000, 1650000],
                    backgroundColor: 'rgba(0, 225, 150, 0.6)',
                  },
                   {
                     label: 'Cost of Goods Sold ($)',
                     values: [5000000, 5500000, 6000000, 7000000, 6500000, 5500000, 5200000, 6800000, 7200000, 6600000],
                     backgroundColor: 'rgba(255, 150, 50, 0.6)',
                   },
                   
                 ]}
                 xAxisTitle = ""
                 yAxisTitle = "Value in USD"
                 secondaryYAxisTitle = "DIO"
                 mode = {mode}
              />
            </div>
          </div>


          {/* Forecast Chart */}
          <div className={`flex flex-col h-[350px] w-1/4 p-3`}>
            <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">Forecast</div>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">DIO is expected to decrease by 10% in 180 days.</div>
            <div className="flex w-full h-[300px]">
              <MultiSeriesBarChart
                label = "Period"
                labels = {['30 Days', '90 Days', '180 Days']}
                datasets = {[
                  {
                    label: 'DIO',
                    values: [4, 3.7, 3.6],
                    backgroundColor: 'rgba(0, 225, 150, 0.6)',
                  },
                ]}
                barColor = "rgba(0,200,180,0.5)"
                yAxisTitle = "DIO"
                xAxisTitle = ""
                mode = {mode}
              />
            </div>
          </div>


        </div>

      </div>
 
      {/* Filter Menu */}
      {showFilter && 
        <ChartFilter 
          showFilter = {showFilter}
          setShowFilter = {setShowFilter}
          updateFormData = {setFormData}
          updateChart = {(formData)=>updateChart(formData)}
        />
      }

    </div>
  )
}

export default WorkingCapitalImpact
