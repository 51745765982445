import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import NetworkGraph2d from './NetworkGraph2d';
import NetworkGraph3D from './NetworkGraph3d';
import Heirarchy from './Heirarchy.js';

import importedAssets from './sample_assets.json';

import dataCenterObjects from './dataCenterObjects.js'


const Relationships = () => {

  const mode = useSelector(state => state.environment.mode);
  const [view, setView] = useState("2d")

  const [assets, setAssets] = useState([])
  
  useEffect(()=>{
    setAssets(importedAssets)
  },[])

  const dispatch = useDispatch()

  
  return (
    <div className="flex flex-col h-[100%] fade-in w-full overflow-y-scroll">

      <div className={`flex h-[50px] w-full items-center text-[16px] transition duration-500 mb-3` }>
          <div 
            className={
              `${view === "2d" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
              p-2 m-2 cursor-pointer text-center w-[50px]`}
            onClick = {(e)=>setView("2d")}
          >2D</div>

          <div 
          className={
            `${view === "3d" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
            p-2 m-2 cursor-pointer text-center w-[50px]`}
            onClick = {(e)=>setView("3d")}
          >3D</div>

        <div 
          className={
            `${view === "tree" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
            p-2 m-2 cursor-pointer text-center w-[50px]`}
            onClick = {(e)=>setView("tree")}
          >Tree</div>
      </div>


            
      {
        view==="2d" ? <NetworkGraph2d mode={mode} assets={assets}/> 
        : 
        view==="3d" ? <NetworkGraph3D mode={mode} assets={assets}/>
        :
        <Heirarchy object ={dataCenterObjects} />

      }
    
    </div>
  )
}

export default Relationships
