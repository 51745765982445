import React, {useEffect, useState} from 'react'
import Kanban from './Kanban'
import * as oomnielabsApi from '../../apis/oomnielabs'

const App = () => {
  const [assets, setAssets] = useState([])
  const [stages, setStages] = useState([])

  const getAssets = async()=>{
    const response = await oomnielabsApi.getPythonTable("assets")
    console.log(response)
    const assetData = response
    setAssets(assetData)

    setStages([
      {id: "1", name: "ordered", label: "Ordered", backgroundColor: "rgba(255,200,100,1)"},
      {id: "2", name: "receiving", label: "Receiving", backgroundColor: "rgba(255,150,100,1)"},
      {id: "3", name: "in_inventory", label: "In Inventory", backgroundColor: "rgba(50,200,0,1)"},
      {id: "4", name: "provisioning", label: "Provisioning", backgroundColor: "rgba(0,150,225,1)"},
      {id: "5", name: "deployed", label: "Deployed", backgroundColor: "rgba(100,0,200,1)"},
      {id: "6", name: "maintenance", label: "Maintenance", backgroundColor: "rgba(150,150,150,1)"},
      {id: "7", name: "decommissioned", label: "Decomissioned", backgroundColor: "rgba(200,0,0,1)"},
    ])
  }

  useEffect(()=>{
    getAssets()
  },[])

  return (
    <div className="flex flex-col w-full h-[100vh]">

      <div className={`flex w-full h-[300px]`}>

      </div>

      <div className="flex w-full h-[600px] overflow-hidden bg-gray-300">
        {assets.length>0  && 
          <Kanban assets={assets} stages={stages}/>
        }
      </div>
    </div>
  )
}

export default App
