import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as oomnielabsApi from '../../../apis/oomnielabs'
import Table from "../../../components/Table.js"
import TrendAnalysis from './TrendAnalysis.js'
import CycleCount from './CycleCount.js';
import PowerConsumption from './PowerConsumption.js'
import UptimeAnalysis from './UptimeAnalysis.js';

const Summary = ({contextApi}) => {

  const mode = useSelector(state => state.environment.mode);

  const [filterData, setFilterData] = useState([])
  const [data, setData] = useState([])

  const [formData, setFormData] = useState({
    start_date:"",
    end_date:"",
    time_interval:"",
    filters: filterData
  })

  const getData = async()=>{
    const query = `
    SELECT 
        TO_CHAR(purchase_date, 'YYYY-MM') AS "Month",
        COUNT(DISTINCT id) AS "Number of Assets",
        SUM(original_purchase_price) AS "Spend",
        COUNT(DISTINCT assigned_to_id) AS "Number of Users",
        ROUND(
            COUNT(DISTINCT CASE 
                WHEN LENGTH(assigned_to_id) > 0 THEN assigned_to_id 
                ELSE NULL 
            END) * 1.0 / COUNT(DISTINCT id), 2) * 100 AS "Percent of Assets Assigned",
        COUNT(DISTINCT commodity_type) AS "Number of Commodities",
        COUNT(DISTINCT supplier) AS "Number of Suppliers",
        ROUND(SUM(kw_consumption), 2) AS "kW Energy Consumption",
        ROUND(SUM(kw_consumption*kg_co2_per_kwhour_emission_rate), 2) AS "kg Co2 Emission"
    FROM assets
    GROUP BY "Month"
    ORDER BY "Month";

    `
    const dbName = "main"
    const response = await oomnielabsApi.getPythonData(query,dbName)
    setData(response)
  }

  const handleRecordSelect = (selectedRecords)=>{
    console.log(selectedRecords)
  }

  useEffect(()=>{
    getData()
  },[])


  
  return (
    <div className="flex flex-col h-[100vh] w-full fade-in items-center overflow-y-auto p-5">
    
     {/* Charts */}
    <div className={`flex flex-col md:w-[1200px] min-w-[600px] h-[5000px] justify-center transition duration-500`}>

      {/* Trend */}
      <div className={`flex w-full h-[400px]`}>
        <TrendAnalysis />
      </div>


      {/* Cycle Count  */}
      <div className={`flex w-full h-[400px]`}>
        <CycleCount />
      </div>


      {/* Power Consumption  */}
      <div className={`flex w-full h-[400px]`}>
        <PowerConsumption />
      </div>


      {/* Uptime  */}
      <div className={`flex w-full h-[400px]`}>
        <UptimeAnalysis />
      </div>


      {/* table */}
      {
        data.length>0 && <div className={`flex flex-col w-full h-[500px]`}>
          <Table 
              data = {data}
              includeRowSelect = {true}
              formatHeader = {true}
              onRowSelected = {(record)=>handleRecordSelect(record)}
              // hiddenColumns = {hiddenColumns}
              mode = {mode}
          />
        </div>
        }

    </div>

    </div>
  )
}

export default Summary
