import React, {useEffect, useState} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import * as oomnielabsApi from '../../apis/oomnielabs'
import Table from '../../components/Table'

const Documents = () => {

  const mode = useSelector(state=>state.environment.mode)
  const [data, setData] = useState([])
  const [selectedRecords, setSelectedRecords] = useState([])

  const getData = async()=>{
    const query = `SELECT 
      label as Name,
      type as Type,
      description as Description,
      record_created as "Record Created",
      last_update as "Last Update"
      FROM documents
      ORDER BY record_created desc
    `
    const response = await oomnielabsApi.getPythonData(query)
    setData(response)
  }

  useEffect(()=>{
    getData()
  },[])

  const handleRecordSelect = (records)=>{
    setSelectedRecords(records)
  }

  const hiddenColumns = []

  return (
    <div className={`flex flex-col w-full h-[100%] body-mode-${mode} mt-5 p-3`}>
    
      {
      data.length>0 && <div className={`flex flex-col w-full h-[600px]`}>
        <Table 
            data = {data}
            includeRowSelect = {true}
            formatHeader = {true}
            onRowSelected = {(record)=>handleRecordSelect(record)}
            hiddenColumns = {hiddenColumns}
            mode = {mode}
        />
      </div>
      }

    </div>
  )
}

export default Documents
