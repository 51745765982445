import { useSelector, useDispatch } from 'react-redux';
import { setCurrentPage } from '../../redux/slices/oomnieDocGenieSlice';

const Navbar = ({}) => {

const dispatch = useDispatch();
const mode = useSelector(state => state.environment.mode);
const currentPage = useSelector(state => state.oomnieDocGenie.currentPage);

  return (

      <div className={`flex h-[50px] w-full items-center text-[16px]]
      header-strip-mode-${mode} primary-color-${mode}
      transition duration-500 mb-3` }>

      <div 
        className={
          `${currentPage === "generate" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
          w-fit p-2 m-2 cursor-pointer text-center`}
        onClick = {(e)=>dispatch(setCurrentPage("generate"))}
      >AI Generate</div>

      <div 
      className={
        `${currentPage === "documents" ?"navbar-item-selected-mode-"+mode : "navbar-item-mode-"+mode}
      w-fit p-2 m-2 cursor-pointer text-center`}
        onClick = {(e)=>dispatch(setCurrentPage("documents"))}
      >Documents</div>

    </div>
  )
}

export default Navbar
