import React, {useState, useEffect} from 'react'
import { useSelector, useDispatch } from 'react-redux';
import ColumnChart from './ColumnChart.js'
import * as oomnielabsApi from '../../../apis/oomnielabs.js'
import Svg from'../../../components/Svg.js'
import ChartFilter from './ChartFilter.js';
import MultiSeriesBarChart from './MultiSeriesBarChart.js';
import DoughnutChart from './DoughnutChart.js';


const UptimeAnalysis = (props) => {

  const mode = useSelector(state => state.environment.mode);
  const [showFilter, setShowFilter] = useState(false)
  const [formData, setFormData] = useState({})
  const [data, setData] = useState([])
  const getData = async()=>{
   
  }


  const getFilteredData = async()=>{

    const app_name = "asset_analysis"
    const main_function = "run_analysis"
    const parameters = formData
    const response = await oomnielabsApi.pythonApp(app_name,main_function, parameters)
    console.log(response)
  }


  const updateChart = ()=>{
    console.log(formData)
  }

  return (

    <div className={`relative flex w-full h-[100%]`}>

     
      {/* Main Chart */}
      <div className={`flex flex-col w-full h-[auto] panel-mode-${mode} mb-5 rounded-md`}>
        
        {/* Chart Header */}
        <div className={`flex w-full h-[50px] items-center header-strip-mode-${mode} pe-3 justify-end`}>
          <div title={"Filter"} onClick={(e)=>setShowFilter(!showFilter)}>
            <Svg 
              iconName = {"FilterIcon"}
              height  = {30}
              width  = {30}
              fillColor  = {"rgb(150,150,150"}
              fillOpacity  = {1}
              isHovered  = {false}
              cursor  = {"pointer"}å
            />
          </div>
        </div>

        {/* Chart Body */}  
       <div className={`flex w-full h-[400px] overflow-hidden rounded-md p-3`}>

        {/* Doughnut Chart */}
        <div className="relative flex flex-col w-1/4 h-[300px] justify-center items-center">
            <div className="flex flex-col h-[300px] justify-center items-center">
              <DoughnutChart
                labels = {['Downtime', 'Uptime']}
                values = {[15, 85]}
                mode = {mode}
              />
            </div>

            <div className="absolute top-[50%] flex w-full flex-col justify-center">
              <div className={`flex w-full justify-center font-bold text-[32px] primary-color-mode-${mode}`}>95%</div>
              <div className={`flex w-full justify-center text-[14px] secondary-color-mode-${mode}`}>Average Uptime</div>
            </div>
        </div>
          
          {/* Bar Chart */}
          <div className={`flex flex-col h-[350px] w-1/2 p-3`}>
            <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">Uptime % My Month</div>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">Uptime has improved by 11%</div>
            <div className="flex w-full h-[250px]">
              <MultiSeriesBarChart
                label = "Average Uptime"
                labels = {['Oct-23', 'Nov-23', 'Dec-23', 'Jan-24', 'Feb-24', 'Mar-24', 'Apr-24', 'May-24', 'Jun-24', 'July-24', 'Aug-24', 'Sep-24']}           
                 datasets = {[
                   {
                     label: 'Moving Average',
                     values: [0, 0, 87.0, 89.0, 91.66666666666667, 94.33333333333333, 96.66666666666667, 98.0, 98.66666666666667, 98.0, 97.66666666666667, 98.0],
                     type: 'line',
                     borderColor: 'rgba(0, 100, 0, 1)',
                     backgroundColor: 'rgba(0, 0, 0, 0)',
                     pointBackgroundColor: 'rgba(0, 0, 225, 1)'
                   },
                   {
                    label: 'Monthly Average',
                    values: [94.5, 94.5, 94.5, 94.5, 94.5, 94.5, 94.5, 94.5, 94.5, 94.5, 94.5, 94.5],
                    type: 'line',
                    borderColor: 'rgba(200, 200, 200, 1)',
                    backgroundColor: 'rgba(0, 0, 0, 0)',
                    pointBackgroundColor: 'rgba(200, 200, 200, 0)'
                  },
                   {
                    label: 'Uptime',
                    values: [86, 87, 88, 92, 95, 96, 99, 99, 98, 97, 98, 99],
                    backgroundColor: 'rgba(0, 150, 225, 0.6)',
                  }   
                 ]}
                 yAxisTitle = "Percent"
                 xAxisTitle = ""
                 secondaryYAxisTitle = "Moving Average"
                 mode = {mode}
              />
            </div>
          </div>


          {/* Forecast Chart */}
          <div className={`flex flex-col h-[350px] w-1/4 p-3`}>
            <div className="flex w-full text-[20px] h-[40px] justify-center items-center font-bold">Forecast</div>
            <div className="flex w-full text-[14px] h-[40px] justify-center items-center text-center mb-2  text-orange-400">Uptime is forecasted to reach 99.2% over 180 days.</div>
            <div className="flex w-full h-[300px]">
              <MultiSeriesBarChart
                label = "Period"
                labels = {['30 Days', '90 Days', '180 Days']}
                datasets = {[
                  { 
                    label: 'Uptime',
                    values: [98, 98.7, 99.2],
                    backgroundColor: 'rgba(0, 225, 150, 0.6)',
                  },
                ]}
                barColor = "rgba(0,200,180,0.5)"
                yAxisTitle = "Percent"
                xAxisTitle = ""
                mode = {mode}
              />
            </div>
          </div>

        </div>
      </div>


      {/* Filter Menu */}
      {showFilter && 
        <ChartFilter 
          showFilter = {showFilter}
          setShowFilter = {setShowFilter}
          updateFormData = {setFormData}
        />
      }

    </div>
  )
}

export default UptimeAnalysis
